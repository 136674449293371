import { PageContext, ReduxAction, ReduxData, ReduxState } from '../redux';

export const LOAD_GROUPS = {
  START: 'LOAD_GROUPS_START',
  DONE: 'LOAD_GROUPS_DONE',
  FAILED: 'LOAD_GROUPS_FAILED',
};

export const LOAD_GROUP = {
  START: 'LOAD_GROUP_START',
  DONE: 'LOAD_GROUP_DONE',
  FAILED: 'LOAD_GROUP_FAILED',
};

export const DELETE_GROUP = {
  START: 'DELETE_GROUP_START',
  DONE: 'DELETE_GROUP_DONE',
  FAILED: 'DELETE_GROUP_FAILED',
};

export const UPGRADE_GROUP = {
  START: 'UPGRADE_GROUP_START',
  DONE: 'UPGRADE_GROUP_DONE',
  FAILED: 'UPGRADE_GROUP_FAILED',
};

export const UPDATE_GROUP = {
  START: 'UPDATE_GROUP_START',
  DONE: 'UPDATE_GROUP_DONE',
  FAILED: 'UPDATE_GROUP_FAILED',
};

export const UPDATE_MOVE_GROUP = {
  START: 'UPDATE_GROUP_MOVE_START',
  DONE: 'UPDATE_GROUP_MOVE_DONE',
  FAILED: 'UPDATE_GROUP_MOVE_FAILED',
};

export const CREATE_GROUP = {
  START: 'CREATE_GROUP_START',
  DONE: 'CREATE_GROUP_DONE',
  FAILED: 'CREATE_GROUP_FAILED',
};

export type GroupModel = {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  isProduction: boolean;
  description: string;
  organizationOrGroupTypeEnum: OrganizationOrGroupTypeEnum;
  devicesCount: number;
  application: {
    id: number;
    type: string;
    name: string;
    code: number;
  };
  auApplication: {
    id: number;
    type: string;
    name: string;
    code: number;
  };
};

export type UpdateGroupData = {
  name?: string;
  applicationID?: number;
  auApplicationID?: number;
  isProduction?: boolean;
  deviceIDs?: number[];
};

export type GroupState = ReduxState<GroupModel>;

export type GroupAction = ReduxAction<GroupData>;

export type GroupData = ReduxData<GroupModel>;

export type GetGroupListData = {
  context: PageContext;
  groups: GroupModel[];
};

export type GroupDispatch = (action: GroupAction) => GroupAction;

export enum OrganizationOrGroupTypeEnum {
  Manufacture = 0,
  Stock = 1,
  Nonconforming = 2,
  Pilot = 3,
  Paid = 4,
}
