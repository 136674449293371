import { LOAD_PROFILE, UserAction, UserState } from '../../types/user/redux';

export const initState: UserState = {
  isLoading: false,
  isFailure: false,
};

export default (state: UserState = initState, action: UserAction): UserState => {
  switch (action.type) {
    case LOAD_PROFILE.START:
      return {
        ...state,
        isLoading: true,
        isFailure: false,
      };
    case LOAD_PROFILE.DONE:
      return {
        ...state,
        profile: action.payload,
        isLoading: false,
        isFailure: false,
      };
    case LOAD_PROFILE.FAILED:
      return {
        ...state,
        isLoading: false,
        isFailure: true,
      };
    default:
      return state;
  }
};
