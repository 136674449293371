import React from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProfileDropdown from './ProfileDropdown';
import { HeaderContentProps } from '../types/components/props';

const HeaderContent = ({ routeKey, computedMatch }: HeaderContentProps) => {
  const { t } = useTranslation('paths');

  const path = `${routeKey}.path`;
  const justify =
    computedMatch.path === t(path) || computedMatch.path === '/' ? 'end' : 'space-between';
  return (
    <Row justify={justify} align={'middle'}>
      <div />

      <ProfileDropdown />
    </Row>
  );
};

HeaderContent.propTypes = {
  routeKey: PropTypes.string,
  computedMatch: PropTypes.shape({
    path: PropTypes.string,
  }),
};

HeaderContent.defaultProps = {
  routeKey: null,
  computedMatch: {
    path: null,
  },
};

export default HeaderContent;
