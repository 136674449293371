import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { initialize } from 'redux-form';
import { get } from 'lodash';
import { Spin } from 'antd';
import ApplicationForm from './forms/ApplicationForm';
import { APPLICATION_TYPE, FORMS } from '../../utils/enums';
import {
  createApplication,
  getApplication,
  updateApplication,
} from '../../redux/applications/actions';
import { history } from '../../utils/history';
import { ApplicationModel, UpdateApplicationModel } from '../../types/applications/redux';
import { ApplicationDetailProps } from '../../types/applications/page';

const ApplicationDetail = ({ computedMatch }: ApplicationDetailProps) => {
  const dispatch = useDispatch();

  const detail = useSelector((state) => get(state, 'applications.detail'));

  const isLoading = get(detail, 'isLoading');

  const { id, type } = computedMatch.params;

  const initEmptyDetailForm = useCallback(() => {
    const predefinedFormValues: Partial<ApplicationModel> = {};
    if (type) {
      let predefinedType = null;

      switch (type) {
        case APPLICATION_TYPE.APPLICATION:
          predefinedType = APPLICATION_TYPE.APPLICATION;
          break;
        case APPLICATION_TYPE.APPLICATION_UPDATER:
          predefinedType = APPLICATION_TYPE.APPLICATION_UPDATER;
          break;
        default:
          break;
      }

      if (predefinedType) {
        predefinedFormValues.type = predefinedType;
      }
    }
    dispatch(initialize(FORMS.APPLICATION_FORM, { ...predefinedFormValues }));
  }, [dispatch]);

  const initDetailForm = useCallback(
    (data) => {
      dispatch(initialize(FORMS.APPLICATION_FORM, { ...data.application }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (id) {
      dispatch(
        getApplication(id, (data) => {
          initDetailForm(data);
        }),
      );
    } else {
      initEmptyDetailForm();
    }
  }, [dispatch, id, initDetailForm, initEmptyDetailForm]);

  const handleUpdate = (body: UpdateApplicationModel) => {
    dispatch(
      updateApplication(id, body, () => {
        dispatch(
          getApplication(id, (data) => {
            initDetailForm(data); //, get(body, 'image', undefined));
          }),
        );
      }),
    );
  };

  const handleCreate = (body: UpdateApplicationModel) => {
    const data = {
      ...body,
      isRelease: get(body, 'isRelease', false),
    };
    dispatch(createApplication(data, () => history.push('/applications')));
  };

  const handleSubmit = (values: UpdateApplicationModel) => {
    if (id) {
      handleUpdate(values);
    } else {
      handleCreate(values);
    }
  };

  return (
    <div className={'page-wrapper'}>
      <Spin spinning={isLoading}>
        <ApplicationForm onSubmit={handleSubmit} isCreate={!id} />
      </Spin>
    </div>
  );
};

ApplicationDetail.propTypes = {
  computedMatch: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
};

ApplicationDetail.defaultProps = {
  computedMatch: null,
};

export default ApplicationDetail;
