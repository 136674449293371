import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import QRCodeReact from 'qrcode.react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { QRCODE_DIALOG } from '../../../utils/enums';
import { getDeviceRegistrationToken } from '../../../redux/devices/actions';

const RegistrationQrCode = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'components']);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');

  const showModal = () => {
    dispatch(
      getDeviceRegistrationToken((token) => {
        setToken(token);
      }),
    );
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {t('pages:device.register')}
      </Button>
      <Modal
        visible={isOpen}
        title={t('pages:device.register')}
        onCancel={handleCancel}
        width={QRCODE_DIALOG.WIDTH}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('components:cancel')}
          </Button>,
        ]}
      >
        <div className={'qrCode-container'}>
          <QRCodeReact
            value={token}
            size={QRCODE_DIALOG.QRCODE_SIZE}
            data-testid="qrcode-test-id"
            level="H"
            imageSettings={{
              src: '/img/qrcode-logo.png',
              height: 85,
              width: 97.75,
              excavate: false,
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default RegistrationQrCode;
