import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { APPLICATION_TYPE } from '../../utils/enums';
import ApplicationsByType from './ApplicationsByType';

const { TabPane } = Tabs;

const Applications = () => {
  const { t } = useTranslation('pages');
  const [selectedApplicationTab, setSelectedApplicationTab] = useState(
    localStorage.getItem('selectedApplicationTab') ?? APPLICATION_TYPE.APPLICATION,
  );

  useEffect(() => {
    localStorage.setItem('selectedApplicationTab', selectedApplicationTab);
  }, [selectedApplicationTab]);

  return (
    <div className={'page-wrapper'}>
      <Tabs
        defaultActiveKey={selectedApplicationTab}
        onChange={setSelectedApplicationTab}
        destroyInactiveTabPane={true}
      >
        <TabPane tab={t('application.types.app')} key={APPLICATION_TYPE.APPLICATION}>
          <ApplicationsByType type={APPLICATION_TYPE.APPLICATION} />
        </TabPane>
        <TabPane tab={t('application.types.au')} key={APPLICATION_TYPE.APPLICATION_UPDATER}>
          <ApplicationsByType type={APPLICATION_TYPE.APPLICATION_UPDATER} />
        </TabPane>
      </Tabs>
    </div>
  );
};

Applications.propTypes = {
  computedMatch: PropTypes.shape({}).isRequired,
};

export default Applications;
