import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormSubmitHandler, initialize } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Spin, Tabs } from 'antd';
import { DeviceTabs, FORMS } from '../../utils/enums';
import { getDevice, updateDevice } from '../../redux/devices/actions';
import DeviceForm from './forms/DeviceForm';
import DeviceMessage from './DeviceMessage';
import { DeviceDetailType, DeviceFormParams } from '../../types/devices/page';
import { PatchDeviceModel } from '../../types/devices/redux';
import { getGroups } from '../../redux/groups/actions';

const { TabPane } = Tabs;

const DeviceDetail = ({ computedMatch }: DeviceDetailType) => {
  const { t } = useTranslation(['pages']);
  const dispatch = useDispatch();

  const detail = useSelector((state) => get(state, 'devices.detail'));
  const isLoading = get(detail, 'isLoading');

  const [selectedDeviceTab, setSelectedDeviceTab] = useState(
    localStorage.getItem('selectedDeviceTab') ?? DeviceTabs.Detail,
  );

  useEffect(() => {
    localStorage.setItem('selectedDeviceTab', selectedDeviceTab);
  }, [selectedDeviceTab]);

  const { id } = computedMatch.params;

  const initDetailForm = useCallback(
    (data) => {
      dispatch(initialize(FORMS.DEVICE_FORM, { ...data.device }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(
      getDevice(id, (data) => {
        initDetailForm(data);
      }),
    );
    dispatch(getGroups({ page: 1, limit: 100000 }));
  }, [dispatch, id, initDetailForm]);

  const handleUpdate = (body: PatchDeviceModel) => {
    dispatch(
      updateDevice(id, body, () => {
        dispatch(
          getDevice(id, (data) => {
            initDetailForm(data);
          }),
        );
      }),
    );
  };

  const handleSubmit: FormSubmitHandler<PatchDeviceModel, DeviceFormParams> = (
    values: PatchDeviceModel,
  ) => {
    handleUpdate(values);
  };

  return (
    <div className={'page-wrapper'}>
      <Spin spinning={isLoading}>
        <Tabs defaultActiveKey={selectedDeviceTab} onChange={setSelectedDeviceTab}>
          <TabPane tab={t('pages:device.detail')} key={DeviceTabs.Detail}>
            <DeviceForm onSubmit={handleSubmit} isCreate={false} />
          </TabPane>
          <TabPane tab={t('pages:message.title')} key={DeviceTabs.MessageHistory}>
            <DeviceMessage messageId={computedMatch.params} />
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );
};

DeviceDetail.propTypes = {
  computedMatch: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

DeviceDetail.defaultProps = {
  computedMatch: null,
};

export default DeviceDetail;
