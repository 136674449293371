import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Empty, Table } from 'antd';
import i18next from 'i18next';
import { get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { setFiltersForPage } from '../../utils/helpers';
import { getDevices } from '../../redux/devices/actions';
import { setFilters } from '../../redux/filters/actions';
import { history } from '../../utils/history';
import { default as RegistrationQrCode } from './components/RegistrationQrCode';
import type { ColumnsType } from 'antd/es/table';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { DeviceModel } from '../../types/devices/redux';
import { getColumnSearchProps, getColumnFilteredValue } from '../../components/ColumnSearch';

const PAGE_SIZE = 20;

const Devices = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'paths', 'components']);
  const persistFilter = useSelector((state) => get(state, 'filters'));
  const [filter, setFilter] = useState(setFiltersForPage(t('paths:devices.key'), persistFilter));

  const accessories = useSelector((state) => get(state, 'devices.list.tableList'));
  const isLoadingAccessories = useSelector((state) => get(state, 'devices.list.isLoading'));
  const isLoadingDetail = useSelector<boolean>((state) => get(state, 'devices.detail.isLoading'));
  const context = useSelector((state) => get(state, 'devices.list.context'));
  const [pagination, setPagination] = useState({
    pageSize: PAGE_SIZE,
    total: 1000,
    current: 1,
    showSizeChanger: true,
    pageSizeOptions: ['20', '50', '100'],
  });

  const renderDashIfNull = (value: string) => value || '-';

  const getCommonColumnProperties = (key: keyof DeviceModel, searchable = false) => ({
    dataIndex: key,
    key: key,
    sorter: true,
    ellipsis: true,
    filteredValue: getColumnFilteredValue(filter, key),
    ...(searchable ? getColumnSearchProps(key, setFilter) : {}),
    render: renderDashIfNull,
  });

  const columns: ColumnsType<DeviceModel> = [
    {
      ...getCommonColumnProperties('uuid', true),
      title: t('pages:device.uuid'),
    },
    {
      ...getCommonColumnProperties('chassisID', true),
      title: t('pages:device.chassis_id'),
    },
    {
      ...getCommonColumnProperties('mobileIMEI', true),
      title: t('pages:device.mobile_imei'),
    },
    {
      ...getCommonColumnProperties('appVersionName', true),
      title: t('pages:device.target_app_version'),
    },
    {
      ...getCommonColumnProperties('appVersionName', true),
      title: t('pages:device.current_app_version'),
    },
    {
      dataIndex: 'deviceGroup.name',
      key: 'deviceGroup.name',
      title: i18next.t('pages:device.group'),
      sorter: true,
      ellipsis: true,
      filteredValue: getColumnFilteredValue(filter, 'deviceGroup'),
      ...getColumnSearchProps('deviceGroup', setFilter),
      render: (text, record) =>
        record.deviceGroup ? record.deviceGroup.name : renderDashIfNull(text),
    },
  ];

  useEffect(() => {
    const body = {
      limit: PAGE_SIZE,
      page: 1,
      ...filter,
    };

    dispatch(getDevices(body));

    dispatch(setFilters(t('paths:devices.key'), body));
  }, [filter, dispatch, t]);

  useEffect(() => {
    if (context && context.totalCount) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: context.totalCount,
      }));
    }
  }, [context]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<keyof DeviceModel, FilterValue | null>,
    sorter: SorterResult<DeviceModel> | SorterResult<DeviceModel>[],
  ) => {
    let order = {};

    if (!isArray(sorter)) {
      if (sorter.order) {
        order = {
          orderBy: sorter.field,
          orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc',
        };
      }
    }

    setFilter((prevFilter) => ({
      ...prevFilter,
      limit: pagination.pageSize,
      page: pagination.current,
      ...order,
    }));

    setPagination({
      pageSize: pagination.pageSize || PAGE_SIZE,
      total: pagination.total || 100,
      current: pagination.current || 1,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100'],
    });
  };

  const clearSearch = () => {
    setFilter((prevFilter) => ({ ...prevFilter, searchBy: null, searchValue: null }));
  };

  return (
    <div className={'page-wrapper'}>
      <div className={'flex justify-between'}>
        <Button onClick={clearSearch}>{t('components:clear_search')}</Button>
        <div />
        <RegistrationQrCode />
      </div>
      <Table
        className={'general-table'}
        columns={columns}
        dataSource={accessories}
        onChange={handleTableChange}
        showSorterTooltip={false}
        rowKey={'id'}
        pagination={pagination}
        loading={isLoadingAccessories || isLoadingDetail}
        onRow={(record) => ({
          onClick: () => history.push(`/devices/${get(record, 'id')}`),
        })}
        locale={{
          emptyText: <Empty description={t('components:empty_list')} />,
        }}
        size={'small'}
      />
    </div>
  );
};

export default Devices;
