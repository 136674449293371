import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Button, Empty, Input } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { debounce, get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { formatBoolean, setFiltersForPage } from '../../utils/helpers';
import { deleteAdministrator, getAdministrators } from '../../redux/administrators/actions';
import { setFilters } from '../../redux/filters/actions';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { AdminPageFilter, RenderValue } from '../../types/administrators/page';
import { SorterResult } from 'antd/es/table/interface';
import { FilterOrder } from '../../types/redux';
import { ColumnType } from 'antd/es/table';
import { getColumnSearchProps, getColumnFilteredValue } from '../../components/ColumnSearch';

const PAGE_SIZE = 20;

const Administrators = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'paths', 'components']);
  const persistFilter = useSelector((state) => get(state, 'filters'));
  const [filter, setFilter] = useState(
    setFiltersForPage<AdminPageFilter>(t('paths:administrators.key'), persistFilter),
  );
  const [pagination, setPagination] = useState({
    pageSize: PAGE_SIZE,
    total: 1000,
    current: 1,
    showSizeChanger: true,
    pageSizeOptions: ['20', '50', '100'],
  });

  const columns: ColumnType<RenderValue>[] = [
    {
      title: t('pages:administrator.email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      ellipsis: true,
      filteredValue: getColumnFilteredValue(filter, 'email'),
      render: (value: string) => value || '-',
      ...getColumnSearchProps('email', setFilter),
    },
    {
      title: t('pages:administrator.name'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      ellipsis: true,
      filteredValue: getColumnFilteredValue(filter, 'firstName'),
      render: (value: string) => value || '-',
      ...getColumnSearchProps('firstName', setFilter),
    },
    {
      title: t('pages:administrator.surname'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
      ellipsis: true,
      filteredValue: getColumnFilteredValue(filter, 'lastName'),
      render: (value: string) => value || '-',
      ...getColumnSearchProps('lastName', setFilter),
    },
    {
      title: t('pages:administrator.role'),
      dataIndex: 'role',
      key: 'role',
      ellipsis: true,
      render: (value: string) => value || '-',
    },
    {
      title: t('pages:administrator.confirmed'),
      dataIndex: 'isConfirmed',
      key: 'isConfirmed',
      ellipsis: true,
      render: (value: boolean) => formatBoolean(value) || '-',
    },
    {
      title: t('pages:administrator.last_login'),
      dataIndex: 'lastLoginAt',
      key: 'lastLoginAt',
      render: (value: string) => (value ? moment(value).format('D. MMM YYYY HH:mm') : '-'),
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (text: string, record: RenderValue) => (
        <>
          <Popconfirm
            title={t('pages:administrator.delete')}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            cancelText={t('components:cancel')}
            okText={t('components:delete')}
            onConfirm={(e) => {
              e?.stopPropagation();
              dispatch(
                deleteAdministrator(record.id, () => {
                  dispatch(
                    getAdministrators({
                      limit: PAGE_SIZE,
                      page: 1,
                      ...filter,
                    }),
                  );
                }),
              );
            }}
            onCancel={(e) => e?.stopPropagation()}
            okButtonProps={{
              size: 'small',
              danger: true,
            }}
            cancelButtonProps={{
              size: 'small',
              type: 'ghost',
            }}
          >
            <Button icon={<DeleteOutlined />} danger={true} onClick={(e) => e.stopPropagation()} />
          </Popconfirm>
        </>
      ),
    },
  ];

  const accessories = useSelector((state) => get(state, 'administrators.list.tableList'));
  const isLoadingAccessories = useSelector((state) => get(state, 'administrators.list.isLoading'));
  const isLoadingDetail = useSelector((state) => get(state, 'administrators.detail.isLoading'));
  const context = useSelector((state) => get(state, 'administrators.list.context'));

  useEffect(() => {
    const body = {
      limit: PAGE_SIZE,
      page: 1,
      ...filter,
    };

    dispatch(getAdministrators(body));

    dispatch(setFilters(t('paths:administrators.key'), body));
  }, [filter, dispatch, t]);

  useEffect(() => {
    if (context && context.totalCount) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: context.totalCount,
      }));
    }
  }, [context]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: AdminPageFilter,
    sorter: SorterResult<RenderValue> | SorterResult<RenderValue>[],
  ) => {
    let order: FilterOrder = {};
    if (!isArray(sorter)) {
      if (sorter.order) {
        order = {
          orderBy: sorter.field,
          orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc',
        };
      }
    }

    setFilter((prevFilter) => ({
      ...prevFilter,
      limit: pagination.pageSize,
      page: pagination.current,
      ...order,
    }));

    setPagination({
      pageSize: pagination.pageSize || PAGE_SIZE,
      total: pagination.total || 100,
      current: pagination.current || 1,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100'],
    });
  };

  const debounced = useCallback(
    debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
    [],
  );

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    debounced(e.target.value);
  };

  const clearSearch = () => {
    setFilter((prevFilter) => ({ ...prevFilter, searchBy: null, searchValue: null }));
  };

  return (
    <div className={'page-wrapper'}>
      <div className={'flex justify-between'}>
        <div />
        <Button onClick={clearSearch} style={{ marginRight: 'auto' }}>
          {t('components:clear_search')}
        </Button>
        <Input.Search
          defaultValue={filter?.search}
          style={{ width: 300, display: 'none' }}
          onChange={handleOnChange}
          allowClear={true}
        />
        <Button
          icon={<PlusCircleOutlined />}
          href={t('paths:administratorCreate.path')}
          type={'primary'}
        >
          {t('pages:administrator.create')}
        </Button>
      </div>
      <Table
        className={'general-table'}
        columns={columns}
        dataSource={accessories}
        onChange={handleTableChange}
        showSorterTooltip={false}
        rowKey={'id'}
        pagination={pagination}
        loading={isLoadingAccessories || isLoadingDetail}
        locale={{
          emptyText: <Empty description={t('components:empty_list')} />,
        }}
        size={'small'}
      />
    </div>
  );
};

Administrators.propTypes = {
  computedMatch: PropTypes.shape({}).isRequired,
};

export default Administrators;
