import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'antd';
import { FORMS } from '../../../utils/enums';
import validateSetNewPasswordForm from './validateSetNewPasswordForm';
import TextField from '../../../atoms/form/TextField';
import { SetNewPasswordFormProps } from '../../../types/authorization/loginPages';
import { SetPasswordModel } from '../../../types/user/redux';

const SetNewPasswordForm = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
}: SetNewPasswordFormProps) => {
  const { t } = useTranslation(['components', 'errors']);

  return (
    <form className={'new-password-form'} onSubmit={handleSubmit}>
      <Field
        name={'password'}
        component={TextField}
        type={'password'}
        label={t('components:new_password')}
        required={true}
      />
      <Field
        name={'confirmPassword'}
        component={TextField}
        type={'password'}
        label={t('components:new_password_again')}
        required={true}
      />
      <Form.Item>
        <Button
          type={'primary'}
          htmlType={'submit'}
          loading={submitting}
          disabled={invalid || pristine}
        >
          {t('components:save')}
        </Button>
      </Form.Item>
    </form>
  );
};

SetNewPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm<SetPasswordModel>({
  form: FORMS.SET_NEW_PASSWORD_FORM,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  touchOnChange: true,
  validate: validateSetNewPasswordForm,
})(SetNewPasswordForm);
