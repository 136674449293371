import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { PageFilter } from '../types/redux';
import { ColumnType } from 'antd/es/table';
import { ColumnSearchProps } from '../types/components/props';

export const ColumnSearch = <TModel,>({
  dataIndex,
  selectedKeys,
  setSelectedKeys,
  setFilter,
  confirm,
  clearFilters,
}: ColumnSearchProps<TModel>) => {
  const { t } = useTranslation(['pages', 'paths', 'components']);

  const handleSearch = (
    selectedKeys: (keyof TModel)[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: string,
  ) => {
    confirm();
    setFilter((prevFilter) => {
      return {
        ...prevFilter,
        searchBy: dataIndex as string,
        searchValue: selectedKeys[0] as string,
      };
    });
  };

  const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void) => {
    clearFilters();
    confirm();
    setFilter((prevFilter) => {
      if (prevFilter) {
        const { searchBy: _, searchValue: __, ...newSearch } = prevFilter;
        return newSearch;
      }

      return prevFilter;
    });
  };

  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`${t('components:search')} ${dataIndex}`}
        value={selectedKeys[0] as string}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value as keyof TModel] : [])}
        onKeyDown={(e) => e.stopPropagation()}
        onPressEnter={() => {
          handleSearch(selectedKeys as (keyof TModel)[], confirm, dataIndex);
        }}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys as (keyof TModel)[], confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          {t('components:search')}
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {t('components:reset')}
        </Button>
      </Space>
    </div>
  );
};

export const getColumnSearchProps = <TModel,>(
  dataIndex: keyof TModel,
  setFilter: React.Dispatch<React.SetStateAction<PageFilter>>,
): Pick<ColumnType<TModel>, 'filterDropdown' | 'filterIcon'> => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: {
    setSelectedKeys: (a: (keyof TModel)[]) => void;
    selectedKeys: (keyof TModel)[];
    confirm: (param?: FilterConfirmProps) => void;
    clearFilters: () => void;
  }) => (
    <ColumnSearch
      clearFilters={clearFilters}
      dataIndex={String(dataIndex)}
      selectedKeys={selectedKeys}
      setFilter={setFilter}
      setSelectedKeys={setSelectedKeys}
      confirm={confirm}
    />
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
});

export const getColumnFilteredValue = <TModel,>(filter: PageFilter, key: keyof TModel) =>
  filter?.searchBy == key ? [filter.searchValue as keyof TModel] : null;
