import { map } from 'lodash';
import i18next from 'i18next';
import { FilterState } from '../types/filters/redux';
import { PageFilter } from '../types/redux';
import { FormErrors } from 'redux-form';

export const formatBoolean = (state: boolean) => {
  if (state) {
    return i18next.t('components:yes');
  }
  return i18next.t('components:no');
};

export const setFiltersForPage = <TFilter extends PageFilter = PageFilter>(
  page: string,
  persistFilter: FilterState<TFilter>,
) => {
  let filters: Partial<TFilter> = {};
  if (persistFilter.page === page) {
    filters = persistFilter?.filters;
  }

  return filters as TFilter;
};

export const getErrorFieldsLabel = (
  formErrors: FormErrors,
  getFieldLabel: { (field: string): string },
) =>
  map(Object.keys(formErrors), (field) => getFieldLabel(field))
    .filter(Boolean)
    .join(', ');

export const getApiVersionFromUrl = (url: string) => {
  const match = url.match(/\/(v\d+)\//);

  if (match) {
    return match[1];
  }

  return null;
};
