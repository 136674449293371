import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { Router } from 'react-router-dom';

import 'antd/dist/antd.min.css';
import './styles/grid.min.css';
import './styles/index.sass';

import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { PersistGate } from 'redux-persist/es/integration/react';
import { history } from './utils/history';
import Routes from './routes/Routes';
import i18n from './utils/i18n';

import configureStore from './redux/configureStore';
import { initializeSentry } from './utils/sentry';

const { store, persistor } = configureStore();

initializeSentry();

const App = () => (
  <Suspense fallback={<Spin style={{ position: 'absolute', left: '50%', top: '50%' }} />}>
    <PersistGate loading={'Loading'} persistor={persistor}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <Router history={history}>
            <Routes />
          </Router>
        </I18nextProvider>
      </Provider>
    </PersistGate>
  </Suspense>
);

export default App;
