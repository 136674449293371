import { mapValues } from 'lodash';
import { getReq, postReq, deleteReq, patchReq } from '../../utils/request';
import { ENDPOINTS } from '../../utils/enums';
import {
  LOAD_DEVICES,
  LOAD_GROUP_DEVICES,
  LOAD_DEVICE,
  UPDATE_DEVICE,
  DELETE_DEVICE,
  LOAD_MESSAGE,
  LOAD_DEVICE_REGISTRATION_TOKEN,
  DeviceDispatch,
  DevicesData,
  DeviceData,
  DeviceMessagesData,
  TokenData,
  DeviceMessageDispatch,
  GetDevicesModel,
  PatchDeviceModel,
  GetDeviceMessages,
} from '../../types/devices/redux';
import { OnFailureFunc, OnSuccessFunc, PageSettings } from '../../types/redux';

export const getDevices = (params: PageSettings) => async (dispatch: DeviceDispatch) => {
  try {
    dispatch({ type: LOAD_DEVICES.START });

    const queries = {
      ...params,
      limit: params.limit ?? 20,
      page: params.page ?? 1,
    };

    const normalizeQueryParams = mapValues(queries, (query) => query || undefined);
    const { data } = await getReq<DevicesData>(ENDPOINTS.DEVICES, normalizeQueryParams);
    dispatch({
      type: LOAD_DEVICES.DONE,
      payload: {
        tableList: data.devices,
        context: data.context,
      },
    });
  } catch (error) {
    dispatch({ type: LOAD_DEVICES.FAILED });
    await Promise.reject(error);
  }
};

export const getDevice =
  (id: number, onSuccess?: OnSuccessFunc<DeviceData>, onFailure?: OnFailureFunc) =>
  async (dispatch: DeviceDispatch) => {
    dispatch({
      type: LOAD_DEVICE.START,
    });
    try {
      const { data } = await getReq<DeviceData>(ENDPOINTS.DEVICE(id));
      dispatch({
        type: LOAD_DEVICE.DONE,
        payload: data,
      });

      return onSuccess && onSuccess(data);
    } catch (error) {
      dispatch({
        type: LOAD_DEVICE.FAILED,
      });
      return onFailure && onFailure(error);
    }
  };

export const deleteDevice =
  (id: number, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: DeviceDispatch) => {
    dispatch({
      type: DELETE_DEVICE.START,
    });
    try {
      await deleteReq(ENDPOINTS.DEVICE(id));
      dispatch({ type: DELETE_DEVICE.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: DELETE_DEVICE.FAILED });
      return onFailure && onFailure(error);
    }
  };

export const getUnassignedDevices =
  (params?: GetDevicesModel) => async (dispatch: DeviceDispatch) => {
    try {
      dispatch({ type: LOAD_DEVICES.START });

      const queries = {
        ...params,
        page: params?.page ?? 1,
        limit: params?.limit ?? 100000,
        isAssigned: params?.isAssigned ?? 'false',
      };

      const { data } = await getReq<DevicesData>(ENDPOINTS.DEVICES, queries);

      const payload = {
        tableList: data.devices,
        context: data.context,
      };

      dispatch({
        type: LOAD_DEVICES.DONE,
        payload,
      });

      return payload;
    } catch (error) {
      dispatch({ type: LOAD_DEVICES.FAILED });
      await Promise.reject(error);
    }
  };

export const getGroupsDevices =
  (
    id: number,
    params?: GetDevicesModel | null,
    onSuccess?: OnSuccessFunc<DevicesData>,
    onFailure?: OnFailureFunc,
  ) =>
  async (dispatch: DeviceDispatch) => {
    try {
      dispatch({ type: LOAD_GROUP_DEVICES.START });

      const queries = {
        ...params,
        page: params?.page ?? 1,
        limit: params?.limit ?? 100000,
        groupID: params?.groupID ?? id,
      };

      const normalizeQueryParams = mapValues(queries, (query) => query || undefined);
      const { data } = await getReq<DevicesData>(ENDPOINTS.DEVICES, normalizeQueryParams);

      dispatch({
        type: LOAD_GROUP_DEVICES.DONE,
        payload: {
          tableList: data.devices,
          context: data.context,
        },
      });

      return onSuccess && onSuccess(data);
    } catch (error) {
      dispatch({ type: LOAD_GROUP_DEVICES.FAILED });
      await Promise.reject(error);
      return onFailure && onFailure(error);
    }
  };

export const updateDevice =
  (id: number, values: PatchDeviceModel, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: DeviceDispatch) => {
    dispatch({
      type: UPDATE_DEVICE.START,
    });
    try {
      const normalizeQueryParams = mapValues(values, (query) => query || undefined);

      await patchReq(ENDPOINTS.DEVICE(id), null, normalizeQueryParams);

      dispatch({ type: UPDATE_DEVICE.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: UPDATE_DEVICE.FAILED });
      return onFailure && onFailure(error);
    }
  };

export const changeDeviceState =
  (id: number, values: string, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async () => {
    try {
      await postReq(
        ENDPOINTS.DEVICES_NOTIFICATIONS,
        {},
        {
          deviceIDs: [id],
          type: 'COMMAND',
          data: {
            command: values,
          },
        },
      );

      return onSuccess && onSuccess();
    } catch (error) {
      return onFailure && onFailure(error);
    }
  };

export const changeGroupDevicesState =
  (id: number, values: string, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async () => {
    try {
      await postReq(
        ENDPOINTS.DEVICES_NOTIFICATIONS,
        {},
        {
          deviceGroupIDs: [id],
          type: 'COMMAND',
          data: {
            command: values,
          },
        },
      );

      return onSuccess && onSuccess();
    } catch (error) {
      return onFailure && onFailure(error);
    }
  };

export const getDeviceMessage =
  (id: number, params: GetDeviceMessages) => async (dispatch: DeviceMessageDispatch) => {
    try {
      dispatch({ type: LOAD_MESSAGE.START });

      const queries = {
        ...params,
        limit: params.limit ?? 20,
        page: params.page ?? 1,
      };

      const normalizeQueryParams = mapValues(queries, (query) => query || undefined);
      const { data } = await getReq<DeviceMessagesData>(
        ENDPOINTS.MESSAGES(id),
        normalizeQueryParams,
      );

      dispatch({
        type: LOAD_MESSAGE.DONE,
        payload: {
          tableList: data.messageList,
          context: data.context,
        },
      });
    } catch (error) {
      dispatch({ type: LOAD_MESSAGE.FAILED });
      await Promise.reject(error);
    }
  };

export const getDeviceRegistrationToken =
  (onSuccess?: OnSuccessFunc<string>, onFailure?: OnFailureFunc) =>
  async (dispatch: DeviceDispatch) => {
    dispatch({
      type: LOAD_DEVICE_REGISTRATION_TOKEN.START,
    });
    try {
      const { data } = await getReq<TokenData>(ENDPOINTS.DEVICE_REGISTRATION_TOKEN);
      const { token } = data;
      return onSuccess && onSuccess(token);
    } catch (error) {
      dispatch({
        type: LOAD_DEVICE_REGISTRATION_TOKEN.FAILED,
      });
      return onFailure && onFailure(error);
    }
  };
