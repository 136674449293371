import { mapValues } from 'lodash';
import { deleteReq, getReq, postReq, patchReq } from '../../utils/request';
import { ENDPOINTS } from '../../utils/enums';
import {
  LOAD_APPLICATIONS,
  LOAD_APPLICATION,
  DELETE_APPLICATION,
  UPDATE_APPLICATION,
  CREATE_APPLICATION,
  ApplicationDispatch,
  ApplicationData,
  ApplicationsListData,
  UpdateApplicationModel,
  ApplicationPageSettings,
} from '../../types/applications/redux';
import { OnFailureFunc, OnSuccessFunc } from '../../types/redux';

export const getApplications =
  (params?: ApplicationPageSettings) => async (dispatch: ApplicationDispatch) => {
    try {
      dispatch({ type: LOAD_APPLICATIONS.START });

      const queries = {
        ...params,
        limit: params?.limit ?? 20,
        page: params?.page ?? 1,
      };

      const normalizeQueryParams = mapValues(queries, (query) => query || undefined);
      const { data } = await getReq<ApplicationsListData>(
        ENDPOINTS.APPLICATIONS,
        normalizeQueryParams,
      );

      dispatch({
        type: LOAD_APPLICATIONS.DONE,
        payload: {
          tableList: data.applications,
          context: data.context,
        },
      });
    } catch (error) {
      dispatch({ type: LOAD_APPLICATIONS.FAILED });
      await Promise.reject(error);
    }
  };

export const getApplication =
  (id: number, onSuccess?: OnSuccessFunc<ApplicationData>, onFailure?: OnFailureFunc) =>
  async (dispatch: ApplicationDispatch) => {
    dispatch({
      type: LOAD_APPLICATION.START,
    });
    try {
      const { data } = await getReq<ApplicationData>(ENDPOINTS.APPLICATION(id));
      dispatch({
        type: LOAD_APPLICATION.DONE,
        payload: data,
      });

      return onSuccess && onSuccess(data);
    } catch (error) {
      dispatch({
        type: LOAD_APPLICATION.FAILED,
      });
      return onFailure && onFailure(error);
    }
  };

export const deleteApplication =
  (id: number, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: ApplicationDispatch) => {
    dispatch({
      type: DELETE_APPLICATION.START,
    });
    try {
      await deleteReq(ENDPOINTS.APPLICATION(id));

      dispatch({ type: DELETE_APPLICATION.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: DELETE_APPLICATION.FAILED });
      return onFailure && onFailure(error);
    }
  };

export const createApplication =
  (values: UpdateApplicationModel, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: ApplicationDispatch) => {
    dispatch({
      type: CREATE_APPLICATION.START,
    });
    try {
      await postReq(ENDPOINTS.APPLICATIONS, null, values);

      dispatch({ type: CREATE_APPLICATION.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: CREATE_APPLICATION.FAILED });
      return onFailure && onFailure(error);
    }
  };

export const updateApplication =
  (
    id: number,
    values: UpdateApplicationModel,
    onSuccess?: OnSuccessFunc,
    onFailure?: OnFailureFunc,
  ) =>
  async (dispatch: ApplicationDispatch) => {
    dispatch({
      type: UPDATE_APPLICATION.START,
    });
    try {
      await patchReq(ENDPOINTS.APPLICATION(id), null, values);

      dispatch({ type: UPDATE_APPLICATION.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: UPDATE_APPLICATION.FAILED });
      return onFailure && onFailure(error);
    }
  };
