import {
  LOAD_APPLICATION,
  LOAD_APPLICATIONS,
  DELETE_APPLICATION,
  UPDATE_APPLICATION,
  CREATE_APPLICATION,
  ApplicationAction,
  ApplicationState,
} from '../../types/applications/redux';

export const initState: ApplicationState = {
  list: {
    tableList: [],
    context: {
      page: 0,
      count: 0,
      totalCount: 0,
    },
    isLastPage: false,
    isLoading: false,
    isFailure: false,
  },
  detail: {
    isLoading: false,
    isFailure: false,
  },
};

export default (state = initState, action: ApplicationAction) => {
  switch (action.type) {
    case LOAD_APPLICATIONS.START:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLastPage: false,
          isLoading: true,
          isFailure: false,
        },
      };
    case LOAD_APPLICATIONS.DONE:
      return {
        ...initState,
        ...state,
        list: {
          ...action.payload,
          isLoading: false,
          isFailure: false,
        },
      };
    case LOAD_APPLICATIONS.FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          isLastPage: false,
          isLoading: false,
          isFailure: true,
        },
      };
    case LOAD_APPLICATION.START:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case LOAD_APPLICATION.DONE:
      return {
        ...initState,
        ...state,
        detail: {
          data: action.payload,
          isLoading: false,
          isFailure: false,
        },
      };
    case LOAD_APPLICATION.FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    case DELETE_APPLICATION.START:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          isFailure: false,
        },
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case DELETE_APPLICATION.DONE:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          isFailure: false,
        },
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: false,
        },
      };
    case DELETE_APPLICATION.FAILED:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          isFailure: true,
        },
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    case UPDATE_APPLICATION.START:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case UPDATE_APPLICATION.DONE:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: false,
        },
      };
    case UPDATE_APPLICATION.FAILED:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    case CREATE_APPLICATION.START:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case CREATE_APPLICATION.DONE:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: false,
        },
      };
    case CREATE_APPLICATION.FAILED:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    default:
      return state;
  }
};
