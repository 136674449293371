import i18next from 'i18next';
import { SetPasswordModel } from '../../../types/user/redux';

export default (values: SetPasswordModel) => {
  const errors: SetPasswordModel = {};

  if (!values.password) {
    errors.password = i18next.t('errors:this_field_is_required');
  }

  if (values.password && values.password.length < 5) {
    errors.password = i18next.t('errors:this_field_is_required');
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = i18next.t('errors:this_field_is_required');
  }

  if (values.confirmPassword && values.password && values.confirmPassword !== values.password) {
    errors.confirmPassword = i18next.t('errors:password_not_match');
  }

  return errors;
};
