import { ReduxAction } from '../redux';

export const LOAD_PROFILE = {
  START: 'LOAD_PROFILE_START',
  DONE: 'LOAD_PROFILE_DONE',
  FAILED: 'LOAD_PROFILE_FAILED',
};

export const CHANGE_PASSWORD = {
  START: 'CHANGE_PASSWORD_START',
  DONE: 'CHANGE_PASSWORD_DONE',
  FAILED: 'CHANGE_PASSWORD_FAILED',
};

export const SET_NEW_PASSWORD_DONE = 'SET_NEW_PASSWORD_DONE';

export type UserProfileModel = {
  accessToken: string;
  refreshToken: string;
  administrator: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    lastLoginAt: Date;
    isConfirmed: boolean;
  };
};

export type UserLoginModel = {
  email?: string;
  password?: string;
};

export type SetPasswordModel = {
  password?: string;
  confirmPassword?: string;
};

export type ChangePasswordModel = {
  oldPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
};

export type UserState = {
  profile?: UserProfileModel;
  isLoading: boolean;
  isFailure: boolean;
};

export type UserAction = ReduxAction<UserProfileModel>;

export type UserDispatch = (action: UserAction) => UserAction;
