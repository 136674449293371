import React from 'react';
import { Card } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LoginForm from './forms/LoginForm';
import { history } from '../../utils/history';
import { loginUser } from '../../redux/user/actions';
import { UserLoginModel } from '../../types/user/redux';

const LoginPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['components', 'pages', 'paths']);

  const handleSubmit = async (values: UserLoginModel) => {
    await dispatch(
      loginUser({ ...values }, () => {
        history.replace('/');
      }),
    );
  };

  return (
    <div className={'flex-container'}>
      <Card style={{ width: '400px' }} bordered={false}>
        <div className={'login-logo'} />
        <LoginForm onSubmit={handleSubmit} />
        <Link to={t('paths:forgottenPassword.path')}>{t('pages:login.reset_password')}</Link>
      </Card>
    </div>
  );
};

LoginPage.propTypes = {};

export default LoginPage;
