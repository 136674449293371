import { PageFilter } from '../redux';

export const SET_FILTERS = 'SET_FILTERS';

export const RESET_FILTERS = 'RESET_FILTERS';

export type FilterState<TFilter = PageFilter> = {
  page: string;
  tab: number;
  filters: TFilter;
};

export type FilterAction = {
  type: string;
  payload?: {
    filters?: {
      page: number;
    };
    page?: string;
  };
};

export type FilterDispatch = (action: FilterAction) => FilterDispatch;
