import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Empty, Spin, Table } from 'antd';
import { get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import { formatBoolean, setFiltersForPage } from '../../utils/helpers';
import { getApplications } from '../../redux/applications/actions';
import { setFilters } from '../../redux/filters/actions';
import { history } from '../../utils/history';
import { APPLICATION_TYPES } from '../../utils/enums';
import { SorterResult } from 'antd/es/table/interface';
import { PageFilter, RenderType } from '../../types/redux';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { ApplicationsByTypeProps } from '../../types/applications/page';
import { getColumnSearchProps, getColumnFilteredValue } from '../../components/ColumnSearch';

const PAGE_SIZE = 20;

const ApplicationsByType = ({ type }: ApplicationsByTypeProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'paths', 'components']);
  const persistFilter = useSelector((state) => get(state, 'filters'));
  const [filter, setFilter] = useState(
    setFiltersForPage(t('paths:applications.key'), persistFilter),
  );
  const [pagination, setPagination] = useState({
    pageSize: PAGE_SIZE,
    total: 1000,
    current: 1,
    showSizeChanger: true,
    pageSizeOptions: ['20', '50', '100'],
  });

  const columns = [
    {
      title: t('pages:application.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      filteredValue: getColumnFilteredValue(filter, 'name'),
      render: (value: string) => value || '-',
      ...getColumnSearchProps('name', setFilter),
    },
    {
      title: t('pages:application.code'),
      dataIndex: 'code',
      key: 'code',
      sorter: true,
      ellipsis: true,
      filteredValue: getColumnFilteredValue(filter, 'code'),
      render: (value: string) => value || '-',
      ...getColumnSearchProps('code', setFilter),
    },
    {
      title: t('pages:application.released'),
      dataIndex: 'isRelease',
      key: 'isRelease',
      sorter: true,
      sortable: true,
      ellipsis: true,
      render: (value: boolean) => formatBoolean(value) || '-',
    },
  ];

  const accessories = useSelector((state) => get(state, 'applications.list.tableList'));
  const isLoadingAccessories = useSelector((state) => get(state, 'applications.list.isLoading'));
  const isLoadingDetail = useSelector((state) => get(state, 'applications.detail.isLoading'));
  const context = useSelector((state) => get(state, 'applications.list.context'));

  useEffect(() => {
    const body = {
      limit: PAGE_SIZE,
      page: 1,
      ...filter,
    };

    dispatch(getApplications({ ...body, type: type }));

    dispatch(setFilters(t('paths:applications.key'), body));
  }, [filter, dispatch, t]);

  useEffect(() => {
    if (context && context.totalCount) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: context.totalCount,
      }));
    }
  }, [context]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: PageFilter,
    sorter: SorterResult<RenderType> | SorterResult<RenderType>[],
  ) => {
    let order = {};
    if (!isArray(sorter)) {
      if (sorter.order) {
        order = {
          orderBy: sorter.field,
          orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc',
        };
      }
    }

    setFilter((prevFilter) => ({
      ...prevFilter,
      limit: pagination.pageSize,
      page: pagination.current,
      ...order,
    }));

    setPagination({
      pageSize: pagination.pageSize || PAGE_SIZE,
      total: pagination.total || 100,
      current: pagination.current || 1,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100'],
    });
  };
  const clearSearch = () => {
    setFilter((prevFilter) => ({ ...prevFilter, searchBy: null, searchValue: null }));
  };

  return (
    <>
      <Spin spinning={isLoadingDetail}>
        <div className={'flex justify-between'}>
          <div />
          <Button style={{ marginRight: 'auto' }} onClick={clearSearch}>
            {t('components:clear_search')}
          </Button>
          <Button
            icon={<PlusCircleOutlined />}
            href={`/applications/create/${type}`}
            type={'primary'}
          >
            {t('pages:application.create')}
          </Button>
        </div>
        <Table
          className={'general-table'}
          columns={columns}
          dataSource={accessories}
          onChange={handleTableChange}
          showSorterTooltip={false}
          rowKey={'id'}
          pagination={pagination}
          onRow={(record) => ({
            onClick: () => history.push(`/applications/${get(record, 'id')}`),
          })}
          loading={isLoadingAccessories || isLoadingDetail}
          locale={{
            emptyText: <Empty description={t('components:empty_list')} />,
          }}
          size={'small'}
        />
      </Spin>
    </>
  );
};

ApplicationsByType.propTypes = {
  type: PropTypes.oneOf([...APPLICATION_TYPES]),
};

ApplicationsByType.defaultProps = {
  type: null,
};

export default ApplicationsByType;
