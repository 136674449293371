import { mapValues } from 'lodash';
import { deleteReq, getReq, postReq, putReq } from '../../utils/request';
import { ENDPOINTS } from '../../utils/enums';
import {
  LOAD_ADMINISTRATORS,
  LOAD_ADMINISTRATOR,
  DELETE_ADMINISTRATOR,
  UPDATE_ADMINISTRATOR,
  CREATE_ADMINISTRATOR,
  AdministratorsDispatch,
  AdministratorsListData,
  AdministratorData,
  UpdateAdministratorModel,
} from '../../types/administrators/redux';
import { OnFailureFunc, OnSuccessFunc, PageSettings } from '../../types/redux';

export const getAdministrators =
  (params: PageSettings) => async (dispatch: AdministratorsDispatch) => {
    try {
      dispatch({ type: LOAD_ADMINISTRATORS.START });

      const queries = {
        ...params,
        limit: params.limit ?? 20,
        page: params.page ?? 1,
      };

      const normalizeQueryParams = mapValues(queries, (query) => query || undefined);
      const { data } = await getReq<AdministratorsListData>(
        ENDPOINTS.ADMINISTRATORS,
        normalizeQueryParams,
      );

      dispatch({
        type: LOAD_ADMINISTRATORS.DONE,
        payload: {
          tableList: data.administrators,
          context: data.context,
        },
      });
    } catch (error) {
      dispatch({ type: LOAD_ADMINISTRATORS.FAILED });
      await Promise.reject(error);
    }
  };

export const getAdministrator =
  (id: number, onSuccess?: OnSuccessFunc<AdministratorData>, onFailure?: OnFailureFunc) =>
  async (dispatch: AdministratorsDispatch) => {
    dispatch({
      type: LOAD_ADMINISTRATOR.START,
    });
    try {
      const { data } = await getReq<AdministratorData>(ENDPOINTS.ADMINISTRATOR(id));
      dispatch({
        type: LOAD_ADMINISTRATOR.DONE,
        payload: data,
      });

      return onSuccess && onSuccess(data);
    } catch (error) {
      dispatch({
        type: LOAD_ADMINISTRATOR.FAILED,
      });
      return onFailure && onFailure(error);
    }
  };

export const deleteAdministrator =
  (id: number, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: AdministratorsDispatch) => {
    dispatch({
      type: DELETE_ADMINISTRATOR.START,
    });
    try {
      await deleteReq(ENDPOINTS.ADMINISTRATOR(id));

      dispatch({ type: DELETE_ADMINISTRATOR.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: DELETE_ADMINISTRATOR.FAILED });
      return onFailure && onFailure(error);
    }
  };

export const createAdministrator =
  (values: UpdateAdministratorModel, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: AdministratorsDispatch) => {
    dispatch({
      type: CREATE_ADMINISTRATOR.START,
    });
    try {
      const normalizeQueryParams = mapValues(values, (query) => query || undefined);

      await postReq(ENDPOINTS.ADMINISTRATORS, null, normalizeQueryParams);

      dispatch({ type: CREATE_ADMINISTRATOR.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: CREATE_ADMINISTRATOR.FAILED });
      return onFailure && onFailure(error);
    }
  };

export const updateAdministrator =
  (
    id: number,
    values: UpdateAdministratorModel,
    onSuccess?: OnSuccessFunc,
    onFailure?: OnFailureFunc,
  ) =>
  async (dispatch: AdministratorsDispatch) => {
    dispatch({
      type: UPDATE_ADMINISTRATOR.START,
    });
    try {
      const normalizeQueryParams = mapValues(values, (query) => query || undefined);

      await putReq(ENDPOINTS.ADMINISTRATOR(id), null, normalizeQueryParams);

      dispatch({ type: UPDATE_ADMINISTRATOR.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: UPDATE_ADMINISTRATOR.FAILED });
      return onFailure && onFailure(error);
    }
  };
