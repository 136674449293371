import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import cx from 'classnames';
import { DateFieldProps } from '../../types/atoms/props';

const DateField = ({
  name,
  label,
  input,
  dateTimeFormat = 'DD.MM.YYYY',
  customClass,
  showLabel,
  customLabelClass,
  meta: { error, touched },
  disabledDate,
  showTime,
  defaultValue,
  defaultPickerValue,
  disabled,
  required,
}: DateFieldProps) => {
  let value;
  if (input.value && moment(input.value).isValid()) {
    value = moment(input.value);
  }
  if (!input.value && defaultValue) {
    value = defaultValue;
  }

  return (
    <div className={cx('input-wrapper', { error: touched && error }, customClass)}>
      {showLabel && (
        <label className={cx(customLabelClass, { required })} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={'input-field'}>
        <DatePicker
          onChange={input.onChange}
          format={dateTimeFormat}
          value={value}
          disabledDate={disabledDate}
          showToday={true}
          showTime={showTime}
          defaultValue={defaultValue}
          defaultPickerValue={defaultPickerValue}
          disabled={disabled}
        />
      </div>
      <div className={'tooltip-error'}>{touched ? error : ''}</div>
    </div>
  );
};

DateField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  dateTimeFormat: PropTypes.string,
  showLabel: PropTypes.bool,
  customClass: PropTypes.string,
  customLabelClass: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  disabledDate: PropTypes.func,
  showTime: PropTypes.bool,
  defaultValue: PropTypes.any,
  defaultPickerValue: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

DateField.defaultProps = {
  name: null,
  label: null,
  dateTimeFormat: null,
  showLabel: true,
  customClass: null,
  customLabelClass: null,
  disabledDate: null,
  showTime: false,
  defaultValue: null,
  defaultPickerValue: moment(),
  disabled: false,
  required: false,
};

export default DateField;
