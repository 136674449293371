import { FilterDispatch, RESET_FILTERS, SET_FILTERS } from '../../types/filters/redux';

export const resetFilters = () => (dispatch: FilterDispatch) => {
  dispatch({
    type: RESET_FILTERS,
  });
};

export const setFilters =
  (page: string, filters: { page: number }) => (dispatch: FilterDispatch) => {
    dispatch({
      type: SET_FILTERS,
      payload: {
        page,
        filters,
      },
    });
  };
