import React from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import EmailForm from './forms/EmailForm';
import { ENDPOINTS } from '../../utils/enums';
import { postReq } from '../../utils/request';
import { ForgottenPasswordValues } from '../../types/administrators/forgottenPassword';

const ForgottenPasswordPage = () => {
  const { t } = useTranslation('pages');

  const handleSubmit = async (values: ForgottenPasswordValues) => {
    try {
      await postReq(ENDPOINTS.ADMIN_FORGOTTEN_PASSWORD, null, values);
    } catch (error) {
      await Promise.reject(error);
    }
  };

  return (
    <div className={'flex-container'}>
      <Card style={{ width: '400px' }} bordered={false}>
        <h1>{t('forgotten_password.password_recovery')}</h1>
        <h5>{t('forgotten_password.password_email')}</h5>
        <EmailForm onSubmit={handleSubmit} />
      </Card>
    </div>
  );
};

ForgottenPasswordPage.propTypes = {};

export default ForgottenPasswordPage;
