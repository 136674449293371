import i18next from 'i18next';
import { FormErrors } from 'redux-form';
import { GroupFormModel } from '../../../types/groups/page';

export default (values: GroupFormModel) => {
  const errors: FormErrors<GroupFormModel> = {};
  if (!values.name) {
    errors.name = i18next.t('errors:this_field_is_required');
  }

  if (!values.applicationID) {
    errors.applicationID = i18next.t('errors:this_field_is_required');
  }

  return errors;
};
