import { PageContext, ReduxAction, ReduxData, ReduxState } from '../redux';

export const LOAD_ADMINISTRATORS = {
  START: 'LOAD_ADMINISTRATORS_START',
  DONE: 'LOAD_ADMINISTRATORS_DONE',
  FAILED: 'LOAD_ADMINISTRATORS_FAILED',
};

export const LOAD_ADMINISTRATOR = {
  START: 'LOAD_ADMINISTRATOR_START',
  DONE: 'LOAD_ADMINISTRATOR_DONE',
  FAILED: 'LOAD_ADMINISTRATOR_FAILED',
};

export const DELETE_ADMINISTRATOR = {
  START: 'DELETE_ADMINISTRATOR_START',
  DONE: 'DELETE_ADMINISTRATOR_DONE',
  FAILED: 'DELETE_ADMINISTRATOR_FAILED',
};

export const UPDATE_ADMINISTRATOR = {
  START: 'UPDATE_ADMINISTRATOR_START',
  DONE: 'UPDATE_ADMINISTRATOR_DONE',
  FAILED: 'UPDATE_ADMINISTRATOR_FAILED',
};

export const CREATE_ADMINISTRATOR = {
  START: 'CREATE_ADMINISTRATOR_START',
  DONE: 'CREATE_ADMINISTRATOR_DONE',
  FAILED: 'CREATE_ADMINISTRATOR_FAILED',
};

export type AdministratorModel = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  lastLoginAt: Date;
  isConfirmed: boolean;
};

export type UpdateAdministratorModel = {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
};

export type AdministratorsListData = {
  context: PageContext;
  administrators: AdministratorModel[];
};

export type AdministratorData = ReduxData<AdministratorModel>;

export type AdministratorsState = ReduxState<AdministratorModel>;

export type AdministratorsAction = ReduxAction<AdministratorData>;

export type AdministratorsDispatch = (action: AdministratorsAction) => AdministratorsAction;
