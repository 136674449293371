import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';
import PropTypes from 'prop-types';
import { Table, Empty, Input, Descriptions, Button, TablePaginationConfig } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import DetailHeader from '../../components/DetailHeader';
import { setFiltersForPage } from '../../utils/helpers';
import { getDeviceMessage } from '../../redux/devices/actions';
import { setFilters } from '../../redux/filters/actions';
import { DeviceFilters, DeviceMessageParams } from '../../types/devices/page';
import { ColumnType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import { FilterOrder, RenderType } from '../../types/redux';

const { TextArea } = Input;
const PAGE_SIZE = 20;

const DeviceMessage = (params: DeviceMessageParams) => {
  const { id } = params.messageId;
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'paths', 'components']);
  const persistFilter = useSelector((state) => get(state, 'filters'));
  const [filter, setFilter] = useState(setFiltersForPage(t('paths:messages.key'), persistFilter));
  const accessories = useSelector((state) => get(state, 'devices.list.tableList'));
  const isLoadingAccessories = useSelector((state) => get(state, 'devices.list.isLoading'));
  const context = useSelector((state) => get(state, 'devices.list.context'));

  useEffect(() => {
    const body = {
      limit: PAGE_SIZE,
      page: 1,
      ...filter,
    };

    dispatch(getDeviceMessage(id, body));

    dispatch(setFilters(t('paths:messages.key'), body));
  }, [filter, dispatch, t]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: DeviceFilters,
    sorter: SorterResult<RenderType> | SorterResult<RenderType>[],
  ) => {
    let order: FilterOrder = {};

    if (!isArray(sorter)) {
      if (sorter?.order) {
        order = {
          orderBy: sorter?.field,
          orderDirection: sorter?.order === 'ascend' ? 'asc' : 'desc',
        };
      }

      setFilter({
        limit: PAGE_SIZE,
        page: pagination.current,
        ...order,
      });
    }
  };

  const handleReload = () => {
    const body = {
      limit: PAGE_SIZE,
      page: get(context, 'page'),
      ...filter,
    };

    dispatch(getDeviceMessage(id, body));
  };

  const columns: ColumnType<RenderType>[] = [
    {
      title: t('pages:message.actionCreatedBy'),
      dataIndex: 'actionCreatedBy',
      key: 'actionCreatedBy',
      sorter: true,
      ellipsis: true,
      width: '10%',
      render: (value: string) => value || '-',
    },
    {
      title: t('pages:message.action'),
      dataIndex: 'action',
      key: 'action',
      sorter: true,
      ellipsis: true,
      render: (value: string) => value || '-',
    },
    {
      title: t('pages:message.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      ellipsis: true,
      defaultSortOrder: 'descend',
      width: '10%',
      render: (value: Date) => (value ? moment(value).format('D. MMM YYYY HH:mm:ss') : '-'),
    },
    {
      title: t('pages:message.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      ellipsis: true,
      width: '5%',
      render: (value: string) => value || '-',
    },
    {
      title: t('pages:message.administrator'),
      dataIndex: 'administrator',
      key: 'administrator',
      sorter: true,
      ellipsis: true,
      width: '15%',
      render: (value: string) => value || '-',
    },
  ];

  return (
    <div className={'flex direction-col justify-start main-content'}>
      <DetailHeader title={t('pages:message.title')} />
      <Table
        className={'general-table'}
        columns={columns}
        dataSource={accessories}
        onChange={handleTableChange}
        showSorterTooltip={false}
        rowKey={'id'}
        pagination={{
          pageSize: get(context, 'count'),
          total: get(context, 'totalCount'),
          current: get(context, 'page'),
          showSizeChanger: false,
        }}
        loading={isLoadingAccessories}
        locale={{
          emptyText: <Empty description={t('components:empty_list')} />,
        }}
        title={() => (
          <Button
            type="primary"
            onClick={() => handleReload()}
            icon={<ReloadOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('components:reload')}
          </Button>
        )}
        expandable={{
          expandedRowRender: (record) => (
            <div>
              <Descriptions title="Action">
                <Descriptions.Item>{record.action}</Descriptions.Item>
              </Descriptions>
              <Descriptions title="Data">
                <Descriptions.Item>
                  <TextArea
                    rows={20}
                    value={JSON.stringify(record.data, null, 2)}
                    readOnly={true}
                  />
                </Descriptions.Item>
              </Descriptions>
            </div>
          ),
          rowExpandable: (record) => record.data !== undefined,
        }}
        size={'small'}
      />
    </div>
  );
};

DeviceMessage.propTypes = {
  computedMatch: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

DeviceMessage.defaultProps = {
  computedMatch: null,
};

export default DeviceMessage;
