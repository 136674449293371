import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { get, map } from 'lodash';
import { notification } from 'antd';
import Layout from '../layouts/Layout';
import Route from './PublicRoute';
import AdminRoute from './AdminRoute';
import { history } from '../utils/history';
import {
  clearAccessToken,
  clearProfile,
  clearRefreshToken,
  getRefreshToken,
  setAccessToken,
} from '../utils/auth';
import NotFoundPage from '../pages/NotFoundPage';
import LoginPage from '../pages/login/LoginPage';

import Administrators from '../pages/administrators/Administrators';
import AdministratorDetail from '../pages/administrators/AdministratorDetail';
import ForgottenPassword from '../pages/login/ForgottenPassword';
import ForgottenPasswordPage from '../pages/forgottenPassword/ForgottenPasswordPage';
import HomePage from '../pages/home/HomePage';
import Devices from '../pages/devices/Devices';
import Groups from '../pages/groups/Groups';
import Applications from '../pages/applications/Applications';
import GroupDetail from '../pages/groups/GroupDetail';
import ApplicationDetail from '../pages/applications/ApplicationDetail';
import { ENDPOINTS } from '../utils/enums';
import DeviceDetail from '../pages/devices/DeviceDetail';

class Routes extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    axios.interceptors.response.use(this.responseSuccessHandler, this.responseErrorHandler);
  }

  responseErrorHandler = async (error) => {
    const { t } = this.props;
    const messages = get(error, 'response.data.messages');
    const status = get(error, 'response.status');

    const key = 'updatable'; // squash more notification, show only last message

    const originalConfig = error.config;

    if (status === 401 && originalConfig.url === ENDPOINTS.REFRESH_TOKEN) {
      // If refresh token failed with 401 --> redirect to login
      clearAccessToken();
      clearRefreshToken();
      clearProfile();
      history.replace('/login');
      return Promise.reject(error);
    }

    if (originalConfig.url !== ENDPOINTS.LOGIN && error.response) {
      // Access Token was expired
      if (status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        const rs = await axios.post(ENDPOINTS.REFRESH_TOKEN, {
          refreshToken: getRefreshToken(),
        });
        const { accessToken } = rs.data.data;
        setAccessToken(accessToken);

        return axios(originalConfig);
      }
    }
    if (status >= 500) {
      notification.error({
        key,
        message: t('errors:something_went_wrong'),
      });
    } else if (status === 403) {
      notification.error({
        key,
        message: get(messages, '[0].message', t('errors:server_connection_fail')),
      });
    } else if (status >= 400) {
      if (get(error, 'response.data.messages')) {
        map(messages, (message) => {
          notification.error({
            key,
            message: get(message, 'message', ''),
          });
        });
      } else {
        map(messages, (message) => {
          notification.error({
            key,
            message: get(message, 'message', ''),
          });
        });
      }
    }

    return Promise.reject(error);
  };

  responseSuccessHandler = (response) => {
    const { t } = this.props;
    if (get(response, 'status') === 200 && get(response, 'data.messages')) {
      notification.success({
        key: 'key',
        message: get(response, 'data.messages[0].message', t('paths:status.success')),
      });
    }
    return Promise.resolve(response);
  };

  render = () => {
    const { t } = this.props;

    return (
      <Switch>
        {/* Routes */}
        <Route
          exact={true}
          routeName={t('paths:login.title')}
          routeKey={t('paths:login.key')}
          path={t('paths:login.path')}
          component={LoginPage}
        />

        <Route
          exact={true}
          routeName={t('paths:setNewPassword.title')}
          routeKey={t('paths:setNewPassword.key')}
          path={t('paths:setNewPassword.path')}
          component={ForgottenPassword}
          isRegistrationConfirm={true}
        />

        <Route
          exact={true}
          routeName={t('paths:forgottenPassword.title')}
          routeKey={t('paths:forgottenPassword.key')}
          path={t('paths:forgottenPassword.path')}
          component={ForgottenPasswordPage}
        />

        <Route
          exact={true}
          routeName={t('paths:resetPassword.title')}
          routeKey={t('paths:resetPassword.key')}
          path={t('paths:resetPassword.path')}
          component={ForgottenPassword}
          isRegistrationConfirm={false}
        />

        <AdminRoute
          exact={true}
          routeName={t('paths:base.title')}
          routeKey={t('paths:base.key')}
          path={t('paths:base.path')}
          component={HomePage}
          layout={Layout}
        />

        <AdminRoute
          exact={true}
          routeName={t('paths:administrators.title')}
          routeKey={t('paths:administrators.key')}
          path={'/administrators'}
          component={Administrators}
          layout={Layout}
        />

        <AdminRoute
          exact={true}
          routeName={t('paths:groups.title')}
          routeKey={t('paths:groups.key')}
          path={t('paths:groups.path')}
          component={Groups}
          layout={Layout}
        />

        <AdminRoute
          exact={true}
          routeName={t('paths:groupCreate.title')}
          routeKey={t('paths:groups.key')}
          path={'/groups/create'}
          component={GroupDetail}
          layout={Layout}
        />

        <AdminRoute
          exact={true}
          routeName={t('paths:groupDetail.title')}
          routeKey={t('paths:groupDetail.key')}
          path={'/groups/:id'}
          component={GroupDetail}
          layout={Layout}
        />

        <AdminRoute
          exact={true}
          routeName={t('paths:applications.title')}
          routeKey={t('paths:applications.key')}
          path={t('paths:applications.path')}
          component={Applications}
          layout={Layout}
        />

        <AdminRoute
          exact={true}
          routeName={t('paths:applicationCreate.title')}
          routeKey={t('paths:applications.key')}
          path={'/applications/create/:type?'}
          component={ApplicationDetail}
          layout={Layout}
        />

        <AdminRoute
          exact={true}
          routeName={t('paths:applicationDetail.title')}
          routeKey={t('paths:applications.key')}
          path={'/applications/:id'}
          component={ApplicationDetail}
          layout={Layout}
        />

        <AdminRoute
          exact={true}
          routeName={t('paths:devices.title')}
          routeKey={t('paths:devices.key')}
          path={t('paths:devices.path')}
          component={Devices}
          layout={Layout}
        />

        <AdminRoute
          exact={true}
          routeName={t('paths:deviceDetail.title')}
          routeKey={t('paths:deviceDetail.key')}
          path={'/devices/:id'}
          component={DeviceDetail}
          layout={Layout}
        />

        <AdminRoute
          exact={true}
          routeName={t('paths:administratorCreate.title')}
          routeKey={t('paths:administratorCreate.key')}
          path={'/administrators/create'}
          component={AdministratorDetail}
          layout={Layout}
        />

        <AdminRoute
          exact={true}
          routeName={t('paths:administratorDetail.title')}
          routeKey={t('paths:administratorDetail.key')}
          path={'/administrators/:id'}
          component={AdministratorDetail}
          layout={Layout}
        />

        <Route component={NotFoundPage} layout={null} />
      </Switch>
    );
  };
}

export default compose(withRouter, withTranslation(['paths', 'errors']))(Routes);
