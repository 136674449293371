import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppstoreOutlined, GroupOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons';

import { useDispatch } from 'react-redux';
import { resetFilters } from '../redux/filters/actions';
import { SideMenuProps } from '../types/components/props';

const SideMenu = ({ routeKey }: SideMenuProps) => {
  const { t } = useTranslation('paths');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetFilters());
  }, [routeKey, dispatch]);

  const menuItems = [
    {
      key: 'administrators',
      icon: <UserOutlined />,
      label: <Link to={'/administrators'}>{t('administrators.title')}</Link>,
    },
    {
      key: 'devices',
      icon: <MobileOutlined />,
      label: <Link to={'/devices'}>{t('devices.title')}</Link>,
    },
    {
      key: 'groups',
      icon: <GroupOutlined />,
      label: <Link to={'/groups'}>{t('groups.title')}</Link>,
    },
    {
      key: 'applications',
      icon: <AppstoreOutlined />,
      label: <Link to={'/applications'}>{t('applications.title')}</Link>,
    },
  ];

  return <Menu theme={'dark'} selectedKeys={[routeKey]} mode={'inline'} items={menuItems} />;
};

SideMenu.propTypes = {
  routeKey: PropTypes.string,
};

SideMenu.defaultProps = {
  routeKey: '',
};

export default SideMenu;
