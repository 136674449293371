import {
  LOAD_ADMINISTRATOR,
  LOAD_ADMINISTRATORS,
  DELETE_ADMINISTRATOR,
  UPDATE_ADMINISTRATOR,
  CREATE_ADMINISTRATOR,
  AdministratorsAction,
  AdministratorsState,
} from '../../types/administrators/redux';

export const initState: AdministratorsState = {
  list: {
    tableList: [],
    context: {
      page: 0,
      count: 0,
      totalCount: 0,
    },
    isLastPage: false,
    isLoading: false,
    isFailure: false,
  },
  detail: {
    isLoading: false,
    isFailure: false,
  },
};

export default (state = initState, action: AdministratorsAction) => {
  switch (action.type) {
    case LOAD_ADMINISTRATORS.START:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLastPage: false,
          isLoading: true,
          isFailure: false,
        },
      };
    case LOAD_ADMINISTRATORS.DONE:
      return {
        ...initState,
        ...state,
        list: {
          ...action.payload,
          isLoading: false,
          isFailure: false,
        },
      };
    case LOAD_ADMINISTRATORS.FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          isLastPage: false,
          isLoading: false,
          isFailure: true,
        },
      };
    case LOAD_ADMINISTRATOR.START:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case LOAD_ADMINISTRATOR.DONE:
      return {
        ...initState,
        ...state,
        detail: {
          data: action.payload,
          isLoading: false,
          isFailure: false,
        },
      };
    case LOAD_ADMINISTRATOR.FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    case DELETE_ADMINISTRATOR.START:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          isFailure: false,
        },
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case DELETE_ADMINISTRATOR.DONE:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          isFailure: false,
        },
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: false,
        },
      };
    case DELETE_ADMINISTRATOR.FAILED:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          isFailure: true,
        },
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    case UPDATE_ADMINISTRATOR.START:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case UPDATE_ADMINISTRATOR.DONE:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: false,
        },
      };
    case UPDATE_ADMINISTRATOR.FAILED:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    case CREATE_ADMINISTRATOR.START:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case CREATE_ADMINISTRATOR.DONE:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: false,
        },
      };
    case CREATE_ADMINISTRATOR.FAILED:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    default:
      return state;
  }
};
