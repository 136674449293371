import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { Upload } from 'antd';
import { DeleteOutlined, LoadingOutlined, PlusOutlined, FileOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { UploadFieldProps } from '../../types/atoms/props';

const UploadField = ({
  name,
  label,
  showLabel,
  customClass,
  customLabelClass,
  required,
  customHelper,
  showCustomHelper,
  meta: { touched, error },
  imageUrl,
  customRequest,
  isLoading,
  onRemove,
  fileList,
}: UploadFieldProps) => {
  const { t } = useTranslation('components');
  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>{t('upload')}</div>
    </div>
  );

  const handleRemove = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onRemove && typeof fileList != 'undefined' && !isEmpty(fileList)) {
      onRemove(fileList[0]);
    }
  };

  const renderImage = imageUrl ? (
    <>
      <FileOutlined style={{ fontSize: 24 }} />
      <br />
      {onRemove ? (
        <div className={'upload-remove-button'} onClick={(e) => handleRemove(e)}>
          <DeleteOutlined />
        </div>
      ) : null}
    </>
  ) : (
    uploadButton
  );

  return (
    <div className={cx('input-wrapper', { error: touched && error }, customClass)}>
      {showLabel && (
        <label className={cx(customLabelClass, { required })} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={'input-field'}>
        {showCustomHelper && (
          <span
            style={{
              fontSize: '0.9rem',
              lineHeight: 'normal',
              display: 'flex',
              color: '#aaaaaa',
            }}
          >
            {customHelper}
          </span>
        )}
        <Upload
          listType={'picture-card'}
          className={cx('upload-field', { 'upload-with-remove': onRemove })}
          fileList={fileList}
          showUploadList={false}
          customRequest={customRequest}
        >
          {isLoading ? <LoadingOutlined /> : renderImage}
        </Upload>
      </div>
      <div className={'tooltip-error'}>{touched ? error : ''}</div>
    </div>
  );
};

UploadField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  customClass: PropTypes.string,
  customLabelClass: PropTypes.string,
  required: PropTypes.bool,
  customHelper: PropTypes.string,
  showCustomHelper: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  imageUrl: PropTypes.string,
  customRequest: PropTypes.func,
  isLoading: PropTypes.bool,
  onRemove: PropTypes.func,
};

UploadField.defaultProps = {
  name: null,
  label: '',
  showLabel: true,
  customClass: null,
  customLabelClass: null,
  required: false,
  customHelper: '',
  showCustomHelper: true,
  imageUrl: null,
  customRequest: null,
  isLoading: false,
  onRemove: null,
  fileList: [],
};

export default UploadField;
