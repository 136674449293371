import {
  LOAD_GROUP,
  LOAD_GROUPS,
  DELETE_GROUP,
  UPDATE_GROUP,
  CREATE_GROUP,
  UPGRADE_GROUP,
  GroupAction,
  GroupState,
} from '../../types/groups/redux';

export const initState: GroupState = {
  list: {
    tableList: [],
    context: {
      page: 0,
      count: 0,
      totalCount: 0,
    },
    isLastPage: false,
    isLoading: false,
    isFailure: false,
  },
  detail: {
    isLoading: false,
    isFailure: false,
  },
};

export default (state = initState, action: GroupAction) => {
  switch (action.type) {
    case LOAD_GROUPS.START:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLastPage: false,
          isLoading: true,
          isFailure: false,
        },
      };
    case LOAD_GROUPS.DONE:
      return {
        ...initState,
        ...state,
        list: {
          ...action.payload,
          isLoading: false,
          isFailure: false,
        },
      };
    case LOAD_GROUPS.FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          isLastPage: false,
          isLoading: false,
          isFailure: true,
        },
      };
    case LOAD_GROUP.START:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case LOAD_GROUP.DONE:
      return {
        ...initState,
        ...state,
        detail: {
          data: action.payload,
          isLoading: false,
          isFailure: false,
        },
      };
    case LOAD_GROUP.FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    case DELETE_GROUP.START:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          isFailure: false,
        },
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case DELETE_GROUP.DONE:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          isFailure: false,
        },
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: false,
        },
      };
    case DELETE_GROUP.FAILED:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          isFailure: true,
        },
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    case UPDATE_GROUP.START:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case UPDATE_GROUP.DONE:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: false,
        },
      };
    case UPDATE_GROUP.FAILED:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    case CREATE_GROUP.START:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case CREATE_GROUP.DONE:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: false,
        },
      };
    case CREATE_GROUP.FAILED:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    case UPGRADE_GROUP.START:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case UPGRADE_GROUP.DONE:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: false,
        },
      };
    case UPGRADE_GROUP.FAILED:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    default:
      return state;
  }
};
