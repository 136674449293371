import i18next from 'i18next';
import { emailRegEx } from '../../../utils/regex';
import { AdministratorModel } from '../../../types/administrators/redux';
import { FormErrors } from 'redux-form';

export default (values: AdministratorModel) => {
  const errors: FormErrors<AdministratorModel> = {};

  if (
    !values.firstName ||
    (values.firstName && !(values.firstName.length >= 2 && values.firstName.length <= 20))
  ) {
    errors.firstName = i18next.t('errors:name_not_valid');
  }

  if (
    !values.lastName ||
    (values.lastName && !(values.lastName.length >= 2 && values.lastName.length <= 20))
  ) {
    errors.lastName = i18next.t('errors:name_not_valid');
  }

  if (!values.email || (values.email && !emailRegEx.test(values.email))) {
    errors.email = i18next.t('errors:email_not_valid');
  }

  return errors;
};
