import i18next from 'i18next';
import { ChangePasswordModel } from '../../types/user/redux';

export default (values: ChangePasswordModel) => {
  const errors: ChangePasswordModel = {};

  if (!values.oldPassword) {
    errors.oldPassword = i18next.t('errors:this_field_is_required');
  }

  if (values.oldPassword && values.oldPassword.length < 5) {
    errors.oldPassword = i18next.t('errors:password_min_length');
  }

  if (!values.newPassword) {
    errors.newPassword = i18next.t('errors:this_field_is_required');
  }

  if (values.newPassword && values.newPassword.length < 5) {
    errors.newPassword = i18next.t('errors:password_min_length');
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = i18next.t('errors:this_field_is_required');
  }

  if (
    values.confirmPassword &&
    values.newPassword &&
    values.confirmPassword !== values.newPassword
  ) {
    errors.confirmPassword = i18next.t('errors:password_not_match');
  }

  return errors;
};
