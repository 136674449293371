import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Button, Empty } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { history } from '../../utils/history';
import { setFiltersForPage } from '../../utils/helpers';
import { getGroups } from '../../redux/groups/actions';
import { setFilters } from '../../redux/filters/actions';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { GroupModel, OrganizationOrGroupTypeEnum } from '../../types/groups/redux';
import { getColumnSearchProps } from '../../components/ColumnSearch';

const PAGE_SIZE = 20;

const Groups = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'paths']);
  const persistFilter = useSelector((state) => get(state, 'filters'));
  const [filter, setFilter] = useState(setFiltersForPage(t('paths:groups.key'), persistFilter));

  const accessories = useSelector((state) => get(state, 'groups.list.tableList'));
  const isLoadingAccessories = useSelector((state) => get(state, 'groups.list.isLoading'));
  const isLoadingDetail = useSelector((state) => get(state, 'groups.detail.isLoading'));
  const context = useSelector((state) => get(state, 'groups.list.context'));
  const [pagination, setPagination] = useState({
    pageSize: PAGE_SIZE,
    total: 1000,
    current: 1,
    showSizeChanger: true,
    pageSizeOptions: ['20', '50', '100'],
  });

  useEffect(() => {
    const body = {
      limit: PAGE_SIZE,
      page: 1,
      ...filter,
    };

    dispatch(getGroups(body));

    dispatch(setFilters(t('paths:groups.key'), body));
  }, [filter, dispatch, t]);

  useEffect(() => {
    if (context && context.totalCount) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: context.totalCount,
      }));
    }
  }, [context]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<keyof GroupModel, FilterValue | null>,
    sorter: SorterResult<GroupModel> | SorterResult<GroupModel>[],
  ) => {
    let order = {};
    if (!isArray(sorter)) {
      if (sorter.order) {
        order = {
          orderBy: sorter.field,
          orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc',
        };
      }
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      limit: pagination.pageSize,
      page: pagination.current,
      ...order,
    }));

    setPagination({
      pageSize: pagination.pageSize || PAGE_SIZE,
      total: pagination.total || 100,
      current: pagination.current || 1,
      showSizeChanger: true,
      pageSizeOptions: ['20', '50', '100'],
    });
  };

  const clearSearch = () => {
    setFilter((prevFilter) => ({ ...prevFilter, searchBy: null, searchValue: null }));
  };

  const columns = [
    {
      title: t('pages:group.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      render: (value: string) => value || '-',
      ...getColumnSearchProps('name', setFilter),
    },
    {
      title: t('pages:group.groupType'),
      dataIndex: 'organizationOrGroupTypeEnum',
      key: 'organizationOrGroupTypeEnum',
      sorter: true,
      ellipsis: true,
      width: 150,
      render: (value: number) => {
        const enumLabel = OrganizationOrGroupTypeEnum[value];
        return enumLabel || '-';
      },
      ...getColumnSearchProps('organizationOrGroupTypeEnum', setFilter),
    },
    {
      title: t('pages:group.devicesCount'),
      dataIndex: 'devicesCount',
      key: 'devicesCount',
      sorter: true,
      ellipsis: true,
      width: 150,
      render: (value: number) => value || '-',
      ...getColumnSearchProps('devicesCount', setFilter),
    },
    {
      title: t('pages:group.description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
      ellipsis: true,
      render: (value: string) => value || '-',
      ...getColumnSearchProps('description', setFilter),
    },
  ];

  return (
    <div className={'page-wrapper'}>
      <div className={'flex justify-between'}>
        <Button onClick={clearSearch}>{t('components:clear_search')}</Button>
        <div />
        <Button icon={<PlusCircleOutlined />} href={'/groups/create'} type={'primary'}>
          {t('pages:group.create')}
        </Button>
      </div>
      <Table
        className={'general-table'}
        columns={columns}
        dataSource={accessories}
        onChange={handleTableChange}
        showSorterTooltip={false}
        rowKey={'id'}
        pagination={pagination}
        loading={isLoadingAccessories || isLoadingDetail}
        onRow={(record) => ({
          onClick: () => history.push(`/groups/${get(record, 'id')}`),
        })}
        locale={{
          emptyText: <Empty description={t('components:empty_list')} />,
        }}
        size={'small'}
      />
    </div>
  );
};

Groups.propTypes = {
  computedMatch: PropTypes.shape({}).isRequired,
};

export default Groups;
