import { PageContext, PageSettings, ReduxAction, ReduxData, ReduxState } from '../redux';

export const LOAD_DEVICES = {
  START: 'LOAD_DEVICES_START',
  DONE: 'LOAD_DEVICES_DONE',
  FAILED: 'LOAD_DEVICES_FAILED',
};
export const LOAD_GROUP_DEVICES = {
  START: 'LOAD_GROUP_DEVICES_START',
  DONE: 'LOAD_GROUP_DEVICES_DONE',
  FAILED: 'LOAD_GROUP_DEVICES_FAILED',
};

export const LOAD_DEVICE = {
  START: 'LOAD_DEVICE_START',
  DONE: 'LOAD_DEVICE_DONE',
  FAILED: 'LOAD_DEVICE_FAILED',
};

export const UPDATE_DEVICE = {
  START: 'UPDATE_DEVICE_START',
  DONE: 'UPDATE_DEVICE_DONE',
  FAILED: 'UPDATE_DEVICE_FAILED',
};

export const DELETE_DEVICE = {
  START: 'DELETE_DEVICE_START',
  DONE: 'DELETE_DEVICE_DONE',
  FAILED: 'DELETE_DEVICE_FAILED',
};

export const LOAD_MESSAGE = {
  START: 'LOAD_MESSAGE_START',
  DONE: 'LOAD_MESSAGE_DONE',
  FAILED: 'LOAD_MESSAGE_FAILED',
};

export const LOAD_DEVICE_REGISTRATION_TOKEN = {
  START: 'LOAD_DEVICE_REGISTRATION_TOKEN_START',
  DONE: 'LOAD_DEVICE_REGISTRATION_TOKEN_DONE',
  FAILED: 'LOAD_DEVICE_REGISTRATION_TOKEN_FAILED',
};

export type DeviceModel = {
  id: number;
  uuid: string;
  appVersionCode: number;
  appVersionName: string;
  appUpdaterVersionCode: number;
  appUpdaterVersionName: string;
  appLauncherVersionCode: number;
  appLauncherVersionName: string;
  androidROMVersion: number;
  androidROMType: string;
  updaterNodeToken: string;
  applicationNodeToken: string;
  mobileIMEI: string;
  chassisID: string;
  poID: string;
  hardwareID: string;
  state: string;
  deviceBatteryStatus: {
    mobileBatteryPercentage: number;
    motherboardBatteryPercentage: number;
    updatedAt: Date;
  };
  deviceGroup: {
    name: string;
  };
  glucometerID: string;
  thermometerID: string;
  bloodPressureMonitorID: string;
  iccid: string;
  createdAt: Date;
  updatedAt: Date;
  groupID: number;
  lastSeen: Date;
};

export type DeviceMessageModel = {
  id: number;
  deviceUuid: string;
  action: string;
  actionCreatedBy: string;
  createdAt: Date;
  status: string;
  administrator: string;
  data: string;
};

export type GetDevicesModel = PageSettings & {
  isAssigned?: string;
  groupID?: number;
  searchBy?: string;
  searchValue?: string;
};

export type PatchDeviceModel = {
  groupID?: number;
  glucometerID?: string;
  thermometerID?: string;
  bloodPressureMonitorID?: string;
  hardwareID?: string;
  chassisID?: string;
  poID?: string;
};

export type DeviceState = ReduxState<DeviceModel> & {
  assignedList: {
    tableList: DeviceModel[];
    context: PageContext;
    isLastPage: boolean;
    isLoading: boolean;
    isFailure: boolean;
  };
};

export type DeviceData = ReduxData<DeviceModel>;

export type DeviceMessageData = ReduxData<DeviceMessageModel>;

export type DeviceAction = ReduxAction<DeviceData>;

export type DeviceMessageAction = ReduxAction<DeviceMessageData>;

export type DevicesData = {
  context: PageContext;
  devices: DeviceModel[];
};

export type DeviceMessagesData = {
  context: PageContext;
  messageList: DeviceMessageModel[];
};

export type TokenData = {
  token: string;
};

export type GetDeviceMessages = PageSettings;

export type DeviceDispatch = (action: DeviceAction) => DeviceAction;

export type DeviceMessageDispatch = (action: DeviceMessageAction) => DeviceMessageAction;
