import axios from 'axios';
import { get, mapValues } from 'lodash';
import { getReq } from './request';

import { ENDPOINTS } from './enums';
import { FileToUpload } from '../types/common';

const awsS3Axios = axios.create();

export async function uploadFile(
  fileToUpload: FileToUpload,
  onProgressUpdate: (percentage: number) => void,
  onSuccess: () => void,
  onError: () => void,
) {
  const { type } = fileToUpload;

  const queries = {
    type: 'APPLICATION_FILE',
  };

  const normalizeQueryParams = mapValues(queries, (query) => query || undefined);
  const { data } = await getReq(ENDPOINTS.UPLOAD_SIGNED_REQUEST, normalizeQueryParams, undefined, {
    'content-type': type,
  });

  await awsS3Axios
    .put(get(data, 'signedUrl'), fileToUpload, {
      headers: {
        'Content-Type': type,
        'x-ms-blob-type': 'BlockBlob',
        ...get(data, 'meta'),
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgressUpdate(percentCompleted);
      },
    })
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });

  return encodeURI(get(data, 'fileUrl'));
}
