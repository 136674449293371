import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { Input } from 'antd';
import { TextFieldProps } from '../../types/atoms/props';

const TextField = ({
  input,
  name,
  label,
  placeholder,
  showLabel,
  customClass,
  customLabelClass,
  customInputClass,
  meta: { touched, error },
  type,
  disabled,
  required,
  onInput,
  onKeyUp,
  onBlur,
  suffix,
  min,
  size,
  counter,
  integer,
  showUntouchedErrors,
}: TextFieldProps) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (type === 'number' && integer) {
      input.onChange(parseInt(value, 10));
    } else {
      input.onChange(value);
    }
  };

  return (
    <div className={cx('input-wrapper', { error: touched && error }, customClass)}>
      {showLabel && (
        <label className={cx(customLabelClass, { required })} htmlFor={name}>
          {counter ? (
            <>
              {`${label}: `}
              <span
                className={'counter'}
              >{`${counter?.label} ${counter?.actualCount}/${counter?.totalCount}`}</span>
            </>
          ) : (
            `${label}`
          )}
        </label>
      )}
      <div className={'input-field'}>
        <Input
          {...input}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          className={cx('input-field', customInputClass, { disabled })}
          onInput={onInput}
          onKeyUp={onKeyUp}
          required={required}
          min={min}
          onBlur={onBlur}
          suffix={suffix}
          size={size}
          onChange={onChange}
        />
      </div>
      <div className={'tooltip-error'}>{showUntouchedErrors || touched ? error : ''}</div>
    </div>
  );
};

TextField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showLabel: PropTypes.bool,
  customClass: PropTypes.string,
  customLabelClass: PropTypes.string,
  customInputClass: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onInput: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
  suffix: PropTypes.string,
  min: PropTypes.number,
  size: PropTypes.string,
  counter: PropTypes.shape({
    label: PropTypes.string,
    actualCount: PropTypes.number,
    totalCount: PropTypes.number,
  }),
  integer: PropTypes.bool,
  showUntouchedErrors: PropTypes.bool,
};

TextField.defaultProps = {
  name: null,
  label: '',
  placeholder: '',
  showLabel: true,
  customClass: null,
  customLabelClass: null,
  customInputClass: null,
  type: 'text',
  disabled: false,
  required: false,
  onInput: null,
  onBlur: null,
  onKeyUp: null,
  suffix: null,
  min: 0,
  size: 'middle',
  counter: null,
  integer: false,
  showUntouchedErrors: false,
};

export default TextField;
