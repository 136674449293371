import { mapValues } from 'lodash';
import { deleteReq, getReq, patchReq, postReq } from '../../utils/request';
import { ENDPOINTS } from '../../utils/enums';
import {
  LOAD_GROUPS,
  LOAD_GROUP,
  DELETE_GROUP,
  UPDATE_GROUP,
  CREATE_GROUP,
  UPGRADE_GROUP,
  GroupDispatch,
  GetGroupListData,
  GroupData,
  UpdateGroupData,
  UPDATE_MOVE_GROUP,
} from '../../types/groups/redux';
import { OnFailureFunc, OnSuccessFunc, PageSettings } from '../../types/redux';
import { GroupDevicesModel } from '../../types/groups/page';

export const getGroups = (params: PageSettings) => async (dispatch: GroupDispatch) => {
  try {
    dispatch({ type: LOAD_GROUPS.START });

    const queries = {
      ...params,
      limit: params.limit ?? 20,
      page: params.page ?? 1,
    };

    const normalizeQueryParams = mapValues(queries, (query) => query || undefined);
    const { data } = await getReq<GetGroupListData>(ENDPOINTS.GROUPS, normalizeQueryParams);

    dispatch({
      type: LOAD_GROUPS.DONE,
      payload: {
        tableList: data.groups,
        context: data.context,
      },
    });
  } catch (error) {
    dispatch({ type: LOAD_GROUPS.FAILED });
    await Promise.reject(error);
  }
};

export const getGroup =
  (id: number, onSuccess?: OnSuccessFunc<GroupData>, onFailure?: OnFailureFunc) =>
  async (dispatch: GroupDispatch) => {
    dispatch({
      type: LOAD_GROUP.START,
    });
    try {
      const { data } = await getReq<GroupData>(ENDPOINTS.GROUP(id));
      dispatch({
        type: LOAD_GROUP.DONE,
        payload: data,
      });

      return onSuccess && onSuccess(data);
    } catch (error) {
      dispatch({
        type: LOAD_GROUP.FAILED,
      });
      return onFailure && onFailure(error);
    }
  };

export const deleteGroup =
  (id: number, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: GroupDispatch) => {
    dispatch({
      type: DELETE_GROUP.START,
    });
    try {
      await deleteReq(ENDPOINTS.GROUP(id));

      dispatch({ type: DELETE_GROUP.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: DELETE_GROUP.FAILED });
      return onFailure && onFailure(error);
    }
  };

export const upgradeGroup =
  (id: number, forceUpdate: boolean, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: GroupDispatch) => {
    dispatch({
      type: UPGRADE_GROUP.START,
    });
    try {
      await patchReq(ENDPOINTS.GROUP_UPGRADE(id), null, { forceUpdate: forceUpdate });

      dispatch({ type: UPGRADE_GROUP.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: UPGRADE_GROUP.FAILED });
      return onFailure && onFailure(error);
    }
  };

export const createGroup =
  (values: UpdateGroupData, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: GroupDispatch) => {
    dispatch({
      type: CREATE_GROUP.START,
    });
    try {
      const normalizeQueryParams = mapValues(values, (query) => {
        if (query === false) {
          return false;
        }
        return query || undefined;
      });

      await postReq(ENDPOINTS.GROUPS, null, normalizeQueryParams);

      dispatch({ type: CREATE_GROUP.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: CREATE_GROUP.FAILED });
      return onFailure && onFailure(error);
    }
  };

export const updateGroup =
  (id: number, values: UpdateGroupData, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: GroupDispatch) => {
    dispatch({
      type: UPDATE_GROUP.START,
    });
    try {
      const normalizeQueryParams = mapValues(values, (query) => {
        if (query === false) {
          return false;
        }
        return query || undefined;
      });

      await patchReq(ENDPOINTS.GROUP(id), null, normalizeQueryParams);

      dispatch({ type: UPDATE_GROUP.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: UPDATE_GROUP.FAILED });
      return onFailure && onFailure(error);
    }
  };

export const moveDeviceInGroup =
  (id: number, values: GroupDevicesModel, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: GroupDispatch) => {
    dispatch({
      type: UPDATE_MOVE_GROUP.START,
    });
    try {
      const normalizeQueryParams = mapValues(values, (query) => query || undefined);

      await patchReq(ENDPOINTS.GROUP_MOVE(id), null, normalizeQueryParams);

      dispatch({ type: UPDATE_MOVE_GROUP.DONE });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({ type: UPDATE_MOVE_GROUP.FAILED });
      return onFailure && onFailure(error);
    }
  };
