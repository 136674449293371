import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { DownOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import PasswordModal from './PasswordModal';
import { history } from '../utils/history';
import { logoutUser } from '../redux/user/actions';

const ProfileDropdown = () => {
  const { t } = useTranslation(['components', 'paths']);
  const dispatch = useDispatch();

  const user = useSelector((state) => get(state, 'user.profile'));

  const [showModal, setShowModal] = useState<boolean | null>(false);

  const handleLogout = async () => {
    await dispatch(
      logoutUser(() => {
        history.replace('/login');
      }),
    );
  };

  const menuItems = [
    {
      key: 0,
      icon: <LockOutlined style={{ marginRight: 5 }} />,
      label: t('components:change_password'),
      onClick: () => setShowModal(true),
    },
    {
      key: 1,
      icon: <LogoutOutlined style={{ marginRight: 5 }} />,
      label: t('paths:logout.title'),
      onClick: () => handleLogout(),
    },
  ];

  return (
    <>
      <Dropdown overlay={<Menu items={menuItems} />} trigger={['click']} placement={'bottomRight'}>
        <span className={'profile-dropdown-item'} onClick={(e) => e.preventDefault()}>
          {`${get(user, 'email', t('components:profile'))}`}
          <DownOutlined style={{ marginLeft: 5 }} />
        </span>
      </Dropdown>
      {showModal && <PasswordModal setShowModal={setShowModal} />}
    </>
  );
};

export default ProfileDropdown;
