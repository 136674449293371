export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const USER_KEY = 'persist:USER';
export const USER_ID = 'user_id';
export const LOGIN_URL = '/login';

/**
 * Removes saved token from localstorage
 */
export function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

/**
 * Removes saved profile from session storage
 */
export function clearProfile() {
  localStorage.removeItem(USER_KEY);
}

/**
 * @return string token
 *
 * Returns acces token saved into storage or null
 */
export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function getUserId() {
  return localStorage.getItem(USER_ID);
}

/**
 * @param token
 *
 * Save user token into localstorage
 */
export function setAccessToken(token: string) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function setRefreshToken(refreshToken: string) {
  return localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export function clearRefreshToken() {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function setUserId(userId: string) {
  return localStorage.setItem(USER_ID, userId);
}

export function clearUserId() {
  localStorage.removeItem(USER_ID);
}

export function removeAccessTokens() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(USER_ID);
}

/**
 * @return boolean
 *
 * check if token exists and try to decode it
 */
export function isLoggedIn(): boolean {
  try {
    return !!getAccessToken();
  } catch (error) {
    return false;
  }
}
