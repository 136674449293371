import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer, { RootState } from './index';
import { persistStore } from 'redux-persist';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { composeWithDevTools } from 'redux-devtools-extension';

function loggerFilter(state: unknown, action: { type: string }): boolean {
  if (action.type.startsWith('persist')) {
    return false;
  }
  if (action.type === '@@redux-form/REGISTER_FIELD') {
    return false;
  }
  return action.type !== '@@redux-form/UNREGISTER_FIELD';
}

function configureStoreDev(initialState?: RootState) {
  const logger = createLogger({
    collapsed: true,
    duration: true,
    predicate: loggerFilter,
  });

  const middlewares = [reduxImmutableStateInvariant(), thunk, logger];

  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );
  const persistor = persistStore(store);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./', () => {
      const nextReducer = require('./').default;
      store.replaceReducer(nextReducer);
    });
  }

  return { store, persistor };
}

function configureStoreProd(initialState?: RootState) {
  const middlewares = [
    // Add other middleware on this line...
    thunk,
  ];
  const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middlewares)));
  const persistor = persistStore(store);
  return { store, persistor };
}

const configureStore =
  process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
