import { SET_FILTERS, RESET_FILTERS, FilterAction, FilterState } from '../../types/filters/redux';

export const initState: FilterState = {
  page: '',
  tab: 2,
  filters: {
    page: 1,
  },
};

export default (state = initState, action: FilterAction) => {
  switch (action.type) {
    case RESET_FILTERS:
      return {
        ...initState,
      };
    case SET_FILTERS:
      return {
        ...initState,
        ...state,
        page: action.payload?.page,
        filters: action.payload?.filters,
      };
    default:
      return state;
  }
};
