import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { AxiosErrorData, Primitive, SentryCallback } from '../types/common';
import { Scope } from '@sentry/types';
import { AxiosError, AxiosRequestHeaders } from 'axios';

export function initializeSentry() {
  try {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: process.env.REACT_APP_VERSION,
      debug: process.env.REACT_APP_SENTRY_LOGGING === 'true' || false,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: [process.env.REACT_APP_FRONTEND_URL || ''],
        }),
      ],
      tracesSampleRate: 0.1,
    });
  } catch (error) {
    /*
     * adblock might block loading of sentry
     * and web can function properly without sentry loaded
     * therefor we ignore this error
     */
  }
}

export function CaptureApiError(
  error: AxiosError<AxiosErrorData>,
  customHeaders: AxiosRequestHeaders,
) {
  if (error?.response?.status == 500) {
    withSentryScope(customHeaders, () => {
      Sentry.captureException(error, error?.response?.data);
    });
  } else if (error?.response?.status != 401 && error?.response?.status != 403) {
    const title = error?.response?.data?.title || error.message;
    const description = error?.response?.data?.description;

    withSentryScope(customHeaders, (scope: Scope) => {
      if (description) {
        scope.setContext('Details', {
          Description: description,
        });
      }
      Sentry.captureMessage(title, 'warning');
    });
  }
}

function withSentryScope(customHeaders: AxiosRequestHeaders, callback: SentryCallback) {
  Sentry.withScope((scope: Scope) => {
    for (const [key, value] of Object.entries(customHeaders)) {
      if (value != null && value !== '') {
        if (key === 'Authorization') {
          continue;
        }

        if (key === 'UserId') {
          scope.setUser({
            id: String(value),
          });
        } else {
          scope.setTag(key, value as Primitive);
        }
      }
    }
    callback(scope);
  });
}
