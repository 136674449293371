import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { FORMS } from '../../../utils/enums';
import TextField from '../../../atoms/form/TextField';
import validateEmailForm from './validateEmailForm';
import { EmailFormProps } from '../../../types/administrators/forgottenPassword';

const EmailForm = ({ invalid, pristine, handleSubmit }: EmailFormProps) => {
  const { t } = useTranslation(['components', 'errors']);
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name={'email'}
        label={t('components:email')}
        type={'text'}
        placeholder={t('components:email')}
        component={TextField}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      />
      <Form.Item>
        <Button type={'primary'} htmlType={'submit'} disabled={invalid || pristine}>
          {t('components:send')}
        </Button>
      </Form.Item>
    </form>
  );
};

EmailForm.propTypes = {
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.FORGOTTEN_PASSWORD_FORM,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  touchOnChange: true,
  validate: validateEmailForm,
})(EmailForm);
