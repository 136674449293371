import axios, { AxiosRequestConfig } from 'axios';
import { v4 } from 'uuid';
import { getAccessToken, getUserId, isLoggedIn } from './auth';
import { CaptureApiError } from './sentry';
import { getApiVersionFromUrl } from './helpers';
import { KeyValueString } from '../types/common';
import { PageQueryParams } from '../types/redux';

function buildHeaders(items: KeyValueString): Record<string, string> {
  const headers = {
    'Content-Type': 'application/json',
    'Request-Id': v4(),
    UserId: getUserId() || '',
    Accept: 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
    Language: 'EN',
    Authorization: '',
  };

  if (isLoggedIn()) {
    headers.Authorization = `Bearer ${getAccessToken()}`;
  }

  return {
    ...headers,
    ...items,
  };
}

/**
 * @param { string } url endpoint
 * @param { Object } params object
 * @param { string } accept header
 * @param { Object } headers
 * @return Promise response
 * Performs get request to url and returns callback with result
 */
export async function getReq<TRetData, TParams = PageQueryParams, THeaders = KeyValueString>(
  url: string,
  params?: TParams,
  accept?: string,
  headers?: THeaders,
) {
  const config: AxiosRequestConfig = {
    headers: buildHeaders({ ...headers, apiVersion: getApiVersionFromUrl(url) }),
  };

  if (accept) {
    config.headers = { ...config.headers, Accept: accept };
  }

  if (params) {
    config.params = params;
  }

  config.data = {};

  try {
    return await axios.get<TRetData>(url, config);
  } catch (error) {
    if (error.isAxiosError) {
      CaptureApiError(error, error.config.headers);
    }
    throw new Error(JSON.stringify(error.response));
  }
}

/**
 * @param { string } url endpoint
 * @param { Object } params object
 * @param { Object } data
 * @param { Object } headers
 * @return Promise response
 * Performs post request to url and returns callback with result
 */
export async function postReq<
  TRetData,
  TParams = KeyValueString,
  TData = KeyValueString,
  THeaders = KeyValueString,
>(url: string, params?: TParams, data?: TData, headers?: THeaders) {
  const config: AxiosRequestConfig = {
    headers: buildHeaders({ ...headers, apiVersion: getApiVersionFromUrl(url) }),
  };

  if (params) {
    config.params = params;
  }

  try {
    return await axios.post<TRetData>(url, data, config);
  } catch (error) {
    if (error.isAxiosError) {
      CaptureApiError(error, error.config.headers);
    }
    throw new Error(JSON.stringify(error.response));
  }
}

/**
 * @param { string } url endpoint
 * @param { Object } params object
 * @param { Object } data body
 * @param { Object } headers
 * @return Promise response
 * Performs put request to url and returns callback with result
 */
export async function putReq<
  TRetData,
  TParams = KeyValueString,
  TData = KeyValueString,
  THeaders = KeyValueString,
>(url: string, params?: TParams, data?: TData, headers?: THeaders) {
  const config: AxiosRequestConfig = {
    headers: buildHeaders({ ...headers, apiVersion: getApiVersionFromUrl(url) }),
  };

  if (params) {
    config.params = params;
  }

  try {
    return await axios.put<TRetData>(url, data, config);
  } catch (error) {
    if (error.isAxiosError) {
      CaptureApiError(error, error.config.headers);
    }
    throw new Error(JSON.stringify(error.response));
  }
}

/**
 * @param { string } url endpoint
 * @param { Object } params object
 * @param { Object } data body
 * @param { Object } headers
 * @return Promise response
 * Performs put request to url and returns callback with result
 */
export async function patchReq<
  TRetData,
  TParams = KeyValueString,
  TData = KeyValueString,
  THeaders = KeyValueString,
>(url: string, params?: TParams, data?: TData, headers?: THeaders) {
  const config: AxiosRequestConfig = {
    headers: buildHeaders({ ...headers, apiVersion: getApiVersionFromUrl(url) }),
  };

  if (params) {
    config.params = params;
  }

  try {
    return await axios.patch<TRetData>(url, data, config);
  } catch (error) {
    if (error.isAxiosError) {
      CaptureApiError(error, error.config.headers);
    }
    throw new Error(JSON.stringify(error.response));
  }
}

/**
 * @param { string } url endpoint
 * @param { Object } params object
 * @param { Object } data
 * @param { Object } headers
 * @return Promise response
 * Performs delete request to url and returns callback with result
 */
export async function deleteReq<
  TRetData,
  TParams = KeyValueString,
  TData = KeyValueString,
  THeaders = KeyValueString,
>(url: string, params?: TParams, data?: TData, headers?: THeaders) {
  const config: AxiosRequestConfig = {
    headers: buildHeaders({ ...headers, apiVersion: getApiVersionFromUrl(url) }),
    data: data,
  };

  if (params) {
    config.params = params;
  }

  try {
    return await axios.delete<TRetData>(url, config);
  } catch (error) {
    if (error.isAxiosError) {
      CaptureApiError(error, error.config.headers);
    }
    throw new Error(JSON.stringify(error.response));
  }
}

/**
 * @param { string } url endpoint
 * @param { Object } params object
 * @param { Object } headers
 * @return Promise response
 * Performs delete request to url and returns callback with result
 */
export async function downloadReq<TRetData, TParams = KeyValueString, THeaders = KeyValueString>(
  url: string,
  params?: TParams,
  headers?: THeaders,
) {
  const config: AxiosRequestConfig = {
    headers: buildHeaders({ ...headers, apiVersion: getApiVersionFromUrl(url) }),
    responseType: 'blob',
  };

  if (params) {
    config.params = params;
  }

  try {
    return await axios.get<TRetData>(url, config);
  } catch (error) {
    if (error.isAxiosError) {
      CaptureApiError(error, error.config.headers);
    }
    throw new Error(JSON.stringify(error.response));
  }
}
