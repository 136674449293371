import { PageContext, PageSettings, ReduxAction, ReduxData, ReduxState } from '../redux';

export const LOAD_APPLICATIONS = {
  START: 'LOAD_APPLICATIONS_START',
  DONE: 'LOAD_APPLICATIONS_DONE',
  FAILED: 'LOAD_APPLICATIONS_FAILED',
};

export const LOAD_APPLICATION = {
  START: 'LOAD_APPLICATION_START',
  DONE: 'LOAD_APPLICATION_DONE',
  FAILED: 'LOAD_APPLICATION_FAILED',
};

export const DELETE_APPLICATION = {
  START: 'DELETE_APPLICATION_START',
  DONE: 'DELETE_APPLICATION_DONE',
  FAILED: 'DELETE_APPLICATION_FAILED',
};

export const UPDATE_APPLICATION = {
  START: 'UPDATE_APPLICATION_START',
  DONE: 'UPDATE_APPLICATION_DONE',
  FAILED: 'UPDATE_APPLICATION_FAILED',
};

export const CREATE_APPLICATION = {
  START: 'CREATE_APPLICATION_START',
  DONE: 'CREATE_APPLICATION_DONE',
  FAILED: 'CREATE_APPLICATION_FAILED',
};

export type ApplicationModel = {
  id: number;
  type: string;
  name: string;
  code: number;
  fileUrl: string;
  isRelease: boolean;
  createdAt: Date;
  upDatedAt: Date;
  devices: {
    id: number;
  }[];
  deviceGroupsApplication: {
    id: number;
  }[];
  deviceGroupsAuApplication: {
    id: number;
  }[];
};

export type UpdateApplicationModel = {
  type?: string;
  name?: string;
  code?: number;
  isRelease?: boolean;
  fileUrl?: string;
};

export type ApplicationState = ReduxState<ApplicationData>;

export type ApplicationAction = ReduxAction<ApplicationData>;

export type ApplicationData = ReduxData<ApplicationModel>;

export type ApplicationsListData = {
  context: PageContext;
  applications: ApplicationModel[];
};

export type ApplicationDispatch = (action: ApplicationAction) => ApplicationAction;

export type ApplicationPageSettings = PageSettings & {
  type?: string | null;
};
