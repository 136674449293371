import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues, InjectedFormProps } from 'redux-form';
import { Button, Modal, Row, Col } from 'antd';
import { get } from 'lodash';
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FORMS } from '../../../utils/enums';
import TextField from '../../../atoms/form/TextField';
import validateForm from './validateForm';
import { history } from '../../../utils/history';
import { deleteAdministrator } from '../../../redux/administrators/actions';
import DetailHeader from '../../../components/DetailHeader';
import { UpdateAdministratorModel } from '../../../types/administrators/redux';
import { AdministratorFormParams } from '../../../types/administrators/page';

const AdministratorForm = (
  params: AdministratorFormParams &
    InjectedFormProps<UpdateAdministratorModel, AdministratorFormParams>,
) => {
  const { handleSubmit, invalid, pristine, isCreate } = params;
  const { t } = useTranslation(['components', 'paths', 'pages', 'errors']);
  const dispatch = useDispatch();

  const fieldValues = useSelector((state) => getFormValues(FORMS.ADMINISTRATOR_FORM)(state));

  const handleRemove = () => {
    Modal.confirm({
      title: t('pages:administrator.delete'),
      icon: <DeleteOutlined />,
      okText: t('components:delete'),
      cancelText: t('components:cancel'),
      okType: 'danger',
      onOk: () => {
        dispatch(
          deleteAdministrator(get(fieldValues, 'id'), () => {
            history.push(t('paths:administrators.path'));
          }),
        );
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={18} className={'grid'}>
          <div className={'flex direction-col justify-start main-content'}>
            <DetailHeader
              title={isCreate ? t('pages:administrator.create') : t('pages:administrator.detail')}
            />

            <Field
              name={'firstName'}
              component={TextField}
              label={t('pages:administrator.name')}
              required={true}
            />
            <Field
              name={'lastName'}
              component={TextField}
              label={t('pages:administrator.surname')}
              required={true}
            />
            <Field
              name={'email'}
              component={TextField}
              label={t('pages:administrator.email')}
              required={true}
            />
          </div>
        </Col>
        <Col span={6} className={'grid'}>
          <div className={'flex direction-col justify-start sidebar-content'}>
            {!isCreate && (
              <Field
                name={'lastLoginAt'}
                component={TextField}
                disabled={true}
                label={t('pages:administrator.last_login')}
              />
            )}
            {!isCreate && (
              <Field
                name={'isConfirmed'}
                component={TextField}
                label={t('pages:administrator.confirmed')}
                disabled={true}
              />
            )}
            <div className={'flex direction-col justify-center'}>
              <Button
                icon={<SaveOutlined />}
                onClick={handleSubmit}
                disabled={pristine || invalid}
                type={'primary'}
                style={{ margin: '20px' }}
              >
                {t('components:save')}
              </Button>

              {!isCreate && (
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemove()}
                  danger={true}
                  style={{ margin: '20px' }}
                >
                  {t('components:delete')}
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </form>
  );
};

AdministratorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isCreate: PropTypes.bool,
};

AdministratorForm.defaultProps = {
  isCreate: false,
};

export default reduxForm<UpdateAdministratorModel, AdministratorFormParams>({
  form: FORMS.ADMINISTRATOR_FORM,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  touchOnChange: true,
  validate: validateForm,
})(AdministratorForm);
