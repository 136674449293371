import { get } from 'lodash';
import { removeAccessTokens, setAccessToken, setRefreshToken, setUserId } from '../../utils/auth';
import {
  CHANGE_PASSWORD,
  ChangePasswordModel,
  UserLoginModel,
  SET_NEW_PASSWORD_DONE,
  SetPasswordModel,
  UserDispatch,
  UserProfileModel,
} from '../../types/user/redux';
import { ENDPOINTS } from '../../utils/enums';
import { postReq } from '../../utils/request';
import { OnFailureFunc, OnSuccessFunc } from '../../types/redux';

export const loginUser =
  (
    values: UserLoginModel,
    onSuccess?: OnSuccessFunc<UserProfileModel>,
    onFailure?: OnFailureFunc,
  ) =>
  async () => {
    try {
      const { data } = await postReq<UserProfileModel, null, UserLoginModel>(
        ENDPOINTS.LOGIN,
        null,
        values,
        undefined,
      );
      await setAccessToken(get(data, 'data.accessToken'));
      await setRefreshToken(get(data, 'data.refreshToken'));
      await setUserId(get(data, 'data.administrator.id'));

      return onSuccess && onSuccess(data);
    } catch (error) {
      return onFailure && onFailure();
    }
  };

export const logoutUser = (onSuccess?: OnSuccessFunc) => async () => {
  await removeAccessTokens();

  return onSuccess && onSuccess();
};

export const setNewPassword =
  (values: SetPasswordModel, token: string, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: UserDispatch) => {
    try {
      await postReq(ENDPOINTS.REGISTRATION_CONFIRM, null, values, {
        Authorization: `Bearer ${token}`,
      });
      dispatch({
        type: SET_NEW_PASSWORD_DONE,
      });
      return onSuccess && onSuccess();
    } catch (error) {
      return onFailure && onFailure();
    }
  };

export const resetPassword =
  (values: SetPasswordModel, token: string, onSuccess: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: UserDispatch) => {
    try {
      await postReq(
        ENDPOINTS.ADMIN_RESET_PASSWORD,
        {},
        { newPassword: values.password },
        { Authorization: `Bearer ${token}` },
      );
      dispatch({
        type: SET_NEW_PASSWORD_DONE,
      });
      return onSuccess && onSuccess();
    } catch (error) {
      return onFailure && onFailure();
    }
  };

export const changePassword =
  (values: ChangePasswordModel, onSuccess?: OnSuccessFunc, onFailure?: OnFailureFunc) =>
  async (dispatch: UserDispatch) => {
    dispatch({ type: CHANGE_PASSWORD.START });
    try {
      await postReq(ENDPOINTS.ADMIN_CHANGE_PASSWORD, null, values);
      dispatch({
        type: CHANGE_PASSWORD.DONE,
      });
      return onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: CHANGE_PASSWORD.FAILED,
      });
      return onFailure && onFailure();
    }
  };
