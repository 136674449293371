import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  const { t } = useTranslation('pages');

  return (
    <div className={'not-found-page'}>
      <div className={'container image-container'}>
        <div className={'title'}>404</div>
        <h1>{t('page_not_found')}</h1>
        <Link className={'btn redirect-button'} to={'/'}>
          {t('back_to_homepage')}
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
