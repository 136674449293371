import i18next from 'i18next';
import { PatchDeviceModel } from '../../../types/devices/redux';
import { FormErrors } from 'redux-form';

export default (values: PatchDeviceModel) => {
  const errors: FormErrors<PatchDeviceModel> = {};
  if (!values.glucometerID) {
    errors.glucometerID = i18next.t('errors:this_field_is_required');
  }

  if (!values.glucometerID) {
    errors.glucometerID = i18next.t('errors:this_field_is_required');
  }

  if (!values.thermometerID) {
    errors.thermometerID = i18next.t('errors:this_field_is_required');
  }

  if (!values.bloodPressureMonitorID) {
    errors.bloodPressureMonitorID = i18next.t('errors:this_field_is_required');
  }

  if (!values.hardwareID) {
    errors.hardwareID = i18next.t('errors:this_field_is_required');
  }

  if (!values.chassisID) {
    errors.chassisID = i18next.t('errors:this_field_is_required');
  }

  if (!values.poID) {
    errors.poID = i18next.t('errors:this_field_is_required');
  }

  return errors;
};
