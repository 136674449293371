import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { Switch } from 'antd';
import { SwitchFieldPropType } from '../../types/atoms/props';

const SwitchField = ({
  input,
  name,
  label,
  showLabel,
  customClass,
  customLabelClass,
  customInputClass,
  meta: { touched, error },
  disabled,
  size,
}: SwitchFieldPropType) => (
  <div className={cx('input-wrapper', { error: touched && error }, customClass)}>
    {showLabel && (
      <label className={cx(customLabelClass)} htmlFor={name}>
        {label}
      </label>
    )}
    <div className={'input-field'}>
      <Switch
        {...input}
        checked={input.value}
        disabled={disabled}
        onChange={input.onChange}
        className={cx('input-field', customInputClass, { disabled })}
        size={size}
      />
    </div>
    <div className={'tooltip-error'}>{touched ? error : ''}</div>
  </div>
);

SwitchField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  customClass: PropTypes.string,
  customLabelClass: PropTypes.string,
  customInputClass: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

SwitchField.defaultProps = {
  name: null,
  label: '',
  showLabel: true,
  customClass: null,
  customLabelClass: null,
  customInputClass: null,
  disabled: false,
  size: 'middle',
};

export default SwitchField;
