import i18next from 'i18next';
import { emailRegEx } from '../../../utils/regex';
import { UserLoginModel } from '../../../types/user/redux';

export default (values: UserLoginModel) => {
  const errors: UserLoginModel = {};

  if (!values.email) {
    errors.email = i18next.t('errors:this_field_is_required');
  }

  if (values.email && !emailRegEx.test(values.email)) {
    errors.email = i18next.t('errors:email_not_valid');
  }

  if (!values.password) {
    errors.password = i18next.t('errors:this_field_is_required');
  }

  return errors;
};
