import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues, change, InjectedFormProps } from 'redux-form';
import { Button, Modal, Row, Col, notification } from 'antd';
import { get } from 'lodash';
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { APPLICATION_TYPE, FORMS } from '../../../utils/enums';
import TextField from '../../../atoms/form/TextField';
import validateForm from './validateForm';
import { history } from '../../../utils/history';
import { deleteApplication } from '../../../redux/applications/actions';
import DetailHeader from '../../../components/DetailHeader';
import UploadInputField from '../../../atoms/form/UploadField';
import { uploadFile } from '../../../utils/fileUploader';
import SwitchField from '../../../atoms/form/SwitchField';
import SelectField from '../../../atoms/form/SelectField';
import { FileToUpload } from '../../../types/common';
import { UpdateApplicationModel } from '../../../types/applications/redux';
import { ApplicationFormParams } from '../../../types/applications/page';
import ProgressBar from '../../../components/ProgressBar';

const ApplicationForm = (
  params: ApplicationFormParams & InjectedFormProps<UpdateApplicationModel, ApplicationFormParams>,
) => {
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const { isCreate, handleSubmit, pristine, invalid } = params;
  const { t } = useTranslation(['components', 'pages', 'errors']);
  const dispatch = useDispatch();

  const [file, setFile] = useState({
    isLoading: false,
    imageUrl: '',
  });

  const fieldValues = useSelector((state) => getFormValues(FORMS.APPLICATION_FORM)(state));

  const handleRemove = () => {
    Modal.confirm({
      title: t('pages:application.delete'),
      icon: <DeleteOutlined />,
      okText: t('components:delete'),
      cancelText: t('components:cancel'),
      okType: 'danger',
      onOk: () => {
        dispatch(
          deleteApplication(get(fieldValues, 'id'), () => {
            history.push('/applications');
          }),
        );
      },
    });
  };

  const handleOnSuccessUpload = () => {
    notification.success({
      key: 'key',
      message: 'File upload',
    });
  };

  const handleOnErrorUpload = () => {
    notification.error({
      key: 'key',
      message: 'File not upload',
    });
  };

  const uploadAPKFile = async ({ file }: { file: FileToUpload }) => {
    setFile({
      isLoading: true,
      imageUrl: '',
    });
    const fileUrl = await uploadFile(
      file,
      setUploadPercentage,
      handleOnSuccessUpload,
      handleOnErrorUpload,
    );

    if (fileUrl) {
      dispatch(change(FORMS.APPLICATION_FORM, 'fileUrl', fileUrl));
      setFile({
        isLoading: false,
        imageUrl: fileUrl,
      });
    }
  };

  const applicationTypesOptions = Object.entries(APPLICATION_TYPE).map((item) => ({
    value: item[1],
    label: t(`pages:application.types.${item[1]}`),
  }));

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={18} className={'grid'}>
          <div className={'flex direction-col justify-start main-content'}>
            <DetailHeader
              title={isCreate ? t('pages:application.create') : t('pages:application.detail')}
            />
            <Field
              name={'type'}
              component={SelectField}
              label={t('pages:application.type')}
              options={applicationTypesOptions}
              required={true}
            />
            <Field
              name={'name'}
              component={TextField}
              label={t('pages:application.name')}
              required={true}
            />
            <Field
              name={'code'}
              component={TextField}
              label={t('pages:application.code')}
              required={true}
            />
            <Field
              name={'isRelease'}
              component={SwitchField}
              label={t('pages:application.released')}
              required={true}
            />

            <Field
              name={'fileUrl'}
              imageUrl={get(fieldValues, 'fileUrl')}
              component={UploadInputField}
              label={t('pages:application.apk_file')}
              customRequest={uploadAPKFile}
              isLoading={file.isLoading}
              required={true}
            />
            {file.isLoading && <ProgressBar percent={uploadPercentage} />}
          </div>
        </Col>
        <Col span={6} className={'grid'}>
          <div className={'flex direction-col justify-start sidebar-content'}>
            <div className={'flex direction-col justify-center'}>
              <Button
                icon={<SaveOutlined />}
                onClick={handleSubmit}
                disabled={pristine || invalid}
                type={'primary'}
                style={{ margin: '20px' }}
              >
                {t('components:save')}
              </Button>

              {!isCreate && (
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemove()}
                  danger={true}
                  style={{ margin: '20px' }}
                >
                  {t('components:delete')}
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </form>
  );
};

ApplicationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isCreate: PropTypes.bool,
};

ApplicationForm.defaultProps = {
  isCreate: false,
};

export default reduxForm<UpdateApplicationModel, ApplicationFormParams>({
  form: FORMS.APPLICATION_FORM,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  touchOnChange: true,
  validate: validateForm,
})(ApplicationForm);
