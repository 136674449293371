import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormSubmitHandler, initialize } from 'redux-form';
import { get, map } from 'lodash';
import { Spin } from 'antd';
import GroupForm from './forms/GroupForm';
import { FORMS } from '../../utils/enums';
import { history } from '../../utils/history';
import { createGroup, getGroup, moveDeviceInGroup, updateGroup } from '../../redux/groups/actions';
import { getGroupsDevices } from '../../redux/devices/actions';
import { getApplications } from '../../redux/applications/actions';
import { OrganizationOrGroupTypeEnum, UpdateGroupData } from '../../types/groups/redux';
import { DeviceModel } from '../../types/devices/redux';
import {
  GroupDetailProps,
  GroupDevicesModel,
  GroupFormModel,
  GroupFormProps,
} from '../../types/groups/page';

const GroupDetail = ({ computedMatch }: GroupDetailProps) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [devicesDataSource, setDevicesDataSource] = useState<DeviceModel[]>([]);
  const { id } = computedMatch.params;

  const initEmptyDetailForm = useCallback(() => {
    const initValues = {
      id: undefined,
      name: undefined,
      applicationID: undefined,
      auApplicationID: undefined,
      isProduction: false,
      description: undefined,
      organizationOrGroupTypeEnum: OrganizationOrGroupTypeEnum[OrganizationOrGroupTypeEnum.Paid],
      devices: devicesDataSource,
    };
    dispatch(initialize(FORMS.GROUP_FORM, { ...initValues }));
  }, [dispatch]);

  const initDetailForm = useCallback(
    (data, devices) => {
      setDevicesDataSource(get(devices, 'devices', []));

      const initValues = {
        id: get(data, 'id'),
        name: get(data, 'name'),
        applicationID: get(data, 'application.id'),
        auApplicationID: get(data, 'auApplication.id'),
        isProduction: get(data, 'isProduction'),
        description: get(data, 'description'),
        organizationOrGroupTypeEnum:
          OrganizationOrGroupTypeEnum[get(data, 'organizationOrGroupTypeEnum')],
        devices: devicesDataSource,
      };
      dispatch(initialize(FORMS.GROUP_FORM, { ...initValues }));
    },
    [dispatch],
  );

  const getDetail = async () => {
    setIsLoading(true);
    await dispatch(getApplications({ page: 1, limit: 100000 }));
    if (id) {
      await dispatch(
        getGroupsDevices(id, null, (devices) => {
          dispatch(
            getGroup(id, (data) => {
              initDetailForm(data, devices);
            }),
          );
        }),
      );
    } else {
      initEmptyDetailForm();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getDetail();
  }, [dispatch, id, initDetailForm, initEmptyDetailForm]);

  const handleUpdate = (body: UpdateGroupData) => {
    dispatch(
      updateGroup(id, body, () => {
        getDetail();
      }),
    );
  };

  const handleCreate = (body: UpdateGroupData) => {
    dispatch(createGroup(body, () => history.push('/groups')));
  };

  const handleSubmit: FormSubmitHandler<GroupFormModel, GroupFormProps> = (values) => {
    const data: UpdateGroupData = {
      ...values,
      deviceIDs: map(values.deviceIDs, (item) => item.key),
    };
    if (id) {
      handleUpdate(data);
    } else {
      handleCreate(data);
    }
  };
  function onMoveHandle(groupDevices: GroupDevicesModel): void {
    dispatch(
      moveDeviceInGroup(id, groupDevices, () => {
        getDetail();
      }),
    );
  }

  return (
    <div className={'page-wrapper'}>
      <Spin spinning={isLoading}>
        <GroupForm
          devicesDataSource={devicesDataSource}
          onMoveHandle={onMoveHandle}
          onSubmit={handleSubmit}
          isCreate={!id}
          id={id}
        />
      </Spin>
    </div>
  );
};

GroupDetail.propTypes = {
  computedMatch: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

GroupDetail.defaultProps = {
  computedMatch: null,
};

export default GroupDetail;
