import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { isLoggedIn } from '../utils/auth';

class AdminRoute extends Route {
  static propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    routeKey: PropTypes.string,
  };

  render() {
    if (!isLoggedIn()) {
      return <Redirect to={'/login'} />;
    }

    if (this.props.layout) {
      return (
        <this.props.layout {...this.props}>
          <this.props.component {...this.props} />
        </this.props.layout>
      );
    }
    return <this.props.component {...this.props} />;
  }
}

export default compose(withTranslation('paths'))(AdminRoute);
