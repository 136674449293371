import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import TextField from '../atoms/form/TextField';
import { FORMS } from '../utils/enums';
import validateChangePasswordForm from './validators/validateChangePasswordForm';
import { ChangePasswordFormProps } from '../types/components/props';

const ChangePasswordForm = ({ handleSubmit }: ChangePasswordFormProps) => {
  const { t } = useTranslation(['components', 'errors']);
  return (
    <form className={'new-password-form'} onSubmit={handleSubmit}>
      <Field
        name={'oldPassword'}
        component={TextField}
        type={'password'}
        label={t('components:old_password')}
        required={true}
      />
      <Field
        name={'newPassword'}
        component={TextField}
        type={'password'}
        label={t('components:new_password')}
        required={true}
      />
      <Field
        name={'confirmPassword'}
        component={TextField}
        type={'password'}
        label={t('components:new_password_again')}
        required={true}
      />
    </form>
  );
};

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.CHANGE_PASSWORD_FORM,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  touchOnChange: true,
  validate: validateChangePasswordForm,
})(ChangePasswordForm);
