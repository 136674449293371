import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString, { ParsedQuery } from 'query-string';
import SetNewPasswordForm from './forms/SetNewPasswordForm';
import { resetPassword, setNewPassword } from '../../redux/user/actions';
import { history } from '../../utils/history';
import { SetPasswordModel } from '../../types/user/redux';
import { ForgottenPasswordProps } from '../../types/administrators/forgottenPassword';

const ForgottenPassword = (props: ForgottenPasswordProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('paths');

  const [token, setToken] = useState<string | string[] | null>('');

  useEffect(() => {
    const params: ParsedQuery = queryString.parse(props.location.search);
    if (!params.token) {
      history.push('/');
    }
    setToken(params.token);
  }, [props.location.search, t]);

  const handleSubmit = (values: SetPasswordModel) => {
    if (props.isRegistrationConfirm) {
      dispatch(
        setNewPassword(values, token as string, () => {
          history.push(t('login.path'));
        }),
      );
    } else {
      dispatch(
        resetPassword(values, token as string, () => {
          history.push(t('login.path'));
        }),
      );
    }
  };
  return (
    <div className={'flex-container'}>
      <Card style={{ width: '400px' }}>
        <div className={'login-logo'} />
        <SetNewPasswordForm onSubmit={handleSubmit} />
      </Card>
    </div>
  );
};

ForgottenPassword.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  isRegistrationConfirm: PropTypes.bool,
};

ForgottenPassword.defaultProps = {
  isRegistrationConfirm: false,
};

export default ForgottenPassword;
