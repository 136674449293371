import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { initialize } from 'redux-form';
import { each, find, get } from 'lodash';
import { Spin } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AdministratorForm from './forms/AdministratorForm';
import { FORMS, LANGUAGES } from '../../utils/enums';
import {
  createAdministrator,
  getAdministrator,
  updateAdministrator,
} from '../../redux/administrators/actions';
import { history } from '../../utils/history';
import { UpdateAdministratorModel } from '../../types/administrators/redux';
import { Dictionary } from '@reduxjs/toolkit';
import { AdministratorDetailProps } from '../../types/administrators/page';

const AdministratorDetail = ({ computedMatch }: AdministratorDetailProps) => {
  const { t } = useTranslation(['components', 'paths']);
  const dispatch = useDispatch();

  const detail = useSelector((state) => get(state, 'administrators.detail'));

  const isLoading = get(detail, 'isLoading');

  const { id } = computedMatch.params;

  const initEmptyDetailForm = useCallback(() => {
    dispatch(initialize(FORMS.ADMINISTRATOR_FORM, {}));
  }, [dispatch]);

  const initDetailForm = useCallback(
    (data, originImage) => {
      const initValues = {
        id: get(data, 'id'),
        firstName: get(data, 'firstName'),
        lastName: get(data, 'lastName'),
        email: get(data, 'email'),
        role: get(data, 'role'),
        urlSlug: get(data, 'urlSlug'),
        isConfirmed: get(data, 'isConfirmed') ? t('components:yes') : t('components:no'),
        lastLoginAt: data.lastLoginAt
          ? moment(data.lastLoginAt).format('H:mm:ss D. MMM YYYY ')
          : '-',
        image: originImage || get(data, 'imagePaths.medium'),
      };

      const langValues: Dictionary<string> = {};

      each(LANGUAGES, (item) => {
        const lang = find(get(data, 'translations'), { language: item });
        langValues[`briefDescription_${item}`] = get(lang, 'briefDescription');
        langValues[`detailedDescription_${item}`] = get(lang, 'detailedDescription');
      });

      dispatch(initialize(FORMS.ADMINISTRATOR_FORM, { ...initValues, ...langValues }));
    },
    [dispatch, t],
  );

  useEffect(() => {
    if (id) {
      dispatch(
        getAdministrator(id, (data) => {
          initDetailForm(data, {});
        }),
      );
    } else {
      initEmptyDetailForm();
    }
  }, [dispatch, id, initDetailForm, initEmptyDetailForm]);

  const handleUpdate = (body: UpdateAdministratorModel) => {
    dispatch(
      updateAdministrator(id, body, () => {
        dispatch(
          getAdministrator(id, (data) => {
            initDetailForm(data, get(body, 'image', undefined));
          }),
        );
      }),
    );
  };

  const handleCreate = (body: UpdateAdministratorModel) => {
    dispatch(createAdministrator(body, () => history.push(t('paths:administrators.path'))));
  };

  const handleSubmit = (values: UpdateAdministratorModel) => {
    const body = {
      firstName: get(values, 'firstName'),
      lastName: get(values, 'lastName'),
      email: get(values, 'email'),
      role: 'ADMINISTRATOR',
    };

    if (id) {
      handleUpdate(body);
    } else {
      handleCreate(body);
    }
  };

  return (
    <div className={'page-wrapper'}>
      <Spin spinning={isLoading}>
        <AdministratorForm onSubmit={handleSubmit} isCreate={!id} />
      </Spin>
    </div>
  );
};

AdministratorDetail.propTypes = {
  computedMatch: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
};

AdministratorDetail.defaultProps = {
  computedMatch: null,
};

export default AdministratorDetail;
