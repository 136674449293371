import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { FormSubmitHandler, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FORMS } from '../utils/enums';
import ChangePasswordForm from './ChangePasswordForm';
import { changePassword } from '../redux/user/actions';
import { ChangePasswordModel } from '../types/user/redux';
import { PasswordModalProps } from '../types/components/props';

const PasswordModal = ({ setShowModal }: PasswordModalProps) => {
  const { t } = useTranslation('components');
  const dispatch = useDispatch();

  const handlePasswordChange: FormSubmitHandler<ChangePasswordModel> = (
    values: ChangePasswordModel,
  ) => {
    dispatch(changePassword(values));
    setShowModal(null);
  };

  return (
    <Modal
      visible={true}
      title={t('change_password')}
      okText={t('save')}
      cancelText={t('cancel')}
      onCancel={() => setShowModal(null)}
      onOk={() => {
        dispatch(submit(FORMS.CHANGE_PASSWORD_FORM));
      }}
    >
      <ChangePasswordForm onSubmit={handlePasswordChange} />
    </Modal>
  );
};

PasswordModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
};

export default PasswordModal;
