import React, { useState } from 'react';
import { Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';

interface SendCommandComponentProps {
  onHandleCommand: (command: string) => void;
}

const SendCommandComponent = ({ onHandleCommand }: SendCommandComponentProps) => {
  const { t } = useTranslation(['pages', 'components']);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);

  const deviceStateOptions = [
    { value: 'SHOW_NAV_BARS', label: t('pages:device.states.show_nav_bars') },
    { value: 'HIDE_NAV_BARS', label: t('pages:device.states.hide_nav_bars') },
    {
      value: 'SET_TELEMONITORING_STATE',
      label: t('pages:device.states.set_telemonitoring_state'),
    },
    {
      value: 'SET_B2C_STATE',
      label: t('pages:device.states.set_b2c_state'),
    },
    { value: 'SET_OUTREACH_STATE', label: t('pages:device.states.set_outreach_state') },
    { value: 'SET_DEPLOYMENT_STATE', label: t('pages:device.states.set_deployment_state') },
    {
      value: 'SET_LANG_SK',
      label: t('pages:device.states.set_lang_sk'),
    },
    {
      value: 'SET_LANG_CZ',
      label: t('pages:device.states.set_lang_cz'),
    },
    {
      value: 'SET_LANG_EN',
      label: t('pages:device.states.set_lang_en'),
    },
    { value: 'LAUNCH_SCASE_APP', label: t('pages:device.states.launch_scase_app') },
    { value: 'LAUNCH_WIFI_SETTINGS', label: t('pages:device.states.launch_wi-fi_settings') },
    {
      value: 'LAUNCH_LOCALE_SETTINGS',
      label: t('pages:device:states.launch_locale_settings'),
    },
    { value: 'LAUNCH_UPDATER_APP', label: t('pages:device.states.launch_updater_app') },
    { value: 'TURN_OFF_USB_LOGGING', label: t('pages:device.states.turn_off_usb_logging') },
    {
      value: 'TURN_ON_USB_LOGGING_ONE_DAY',
      label: t('pages:device.states.turn_on_usb_logging_one_day'),
    },
    {
      value: 'REFRESH_BATTERY_STATUS',
      label: t('pages:device:states.refresh_battery_status'),
    },
    { value: 'UPDATE_STATUS', label: t('pages:device.states.update_status') },
    { value: 'SHUTDOWN_MOBILE', label: t('pages:device.states.shutdown_mobile_phone') },
    { value: 'REBOOT_MOBILE', label: t('pages:device.states.reboot_mobile_phone') },
    {
      value: 'TURN_ON_USB_LOGGING_ONE_WEEK',
      label: t('pages:device.states.turn_on_usb_logging_one_week'),
    },
    { value: 'DATA_ROAMING_ON', label: t('pages:device.states.turn_on_data_roaming') },
    { value: 'DATA_ROAMING_OFF', label: t('pages:device.states.turn_off_data_roaming') },
    { value: 'MOBILE_DATA_ON', label: t('pages:device.states.turn_on_mobile_data') },
    { value: 'MOBILE_DATA_OFF', label: t('pages:device.states.turn_off_mobile_data') },
    { value: 'WIFI_ON', label: t('pages:device.states.turn_wi-fi_on') },
    { value: 'WIFI_OFF', label: t('pages:device.states.turn_wi-fi_off') },
    { value: 'LOCK_APP', label: t('pages:device.states.lock_application') },
    { value: 'INSTALL_UPDATER_UPDATE', label: t('pages:device.states.install_updater_update') },
  ];

  const handleChange = (value: string) => {
    setSelectedValue(value);
  };

  const handleButtonClick = () => {
    if (selectedValue) {
      if (onHandleCommand) {
        onHandleCommand(selectedValue);
        setSelectedValue(undefined);
      }
    }
  };

  return (
    <>
      <div className={'flex direction-col justify-start'}>
        <div className={'flex direction-col justify-center'}>
          <label style={{ fontWeight: 'bold' }}>{t('components:change_state')}</label>
          <Select
            placeholder="Command"
            options={deviceStateOptions}
            onChange={handleChange}
            value={selectedValue}
          />
          <Button
            icon={<SaveOutlined />}
            type="primary"
            onClick={handleButtonClick}
            disabled={selectedValue === undefined}
          >
            {t('components:send_command')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SendCommandComponent;
