export const NAMESPACE = {
  PAGES: 'pages',
  COMPONENTS: 'components',
  PATHS: 'paths',
  ERRORS: 'errors',
};

export const DEFAULT_LOCALE = 'en';

export const ENDPOINTS = {
  LOGIN: '/api/admin/v1/authorization/login',
  REFRESH_TOKEN: '/api/admin/v1/authorization/refresh-token',
  DEVICES: '/api/admin/v1/devices',
  DEVICES_NOTIFICATIONS: '/api/admin/v1/devices/notifications',
  DEVICE: (id: number) => `/api/admin/v1/devices/${id}`,
  DEVICE_REGISTRATION_TOKEN: '/api/admin/v1/devices/registration',
  MESSAGES: (id: number) => `/api/admin/v1/devices/${id}/messages`,
  APPLICATIONS: '/api/admin/v1/applications',
  APPLICATION: (id: number) => `/api/admin/v1/applications/${id}`,
  GROUPS: '/api/admin/v1/devices/groups',
  GROUP: (id: number) => `/api/admin/v1/devices/groups/${id}`,
  GROUP_MOVE: (id: number) => `/api/admin/v1/devices/groups/${id}/move-device`,
  GROUP_UPGRADE: (id: number) => `/api/admin/v1/devices/groups/upgrade/${id}`,
  UPLOAD_SIGNED_REQUEST: '/api/admin/v1/upload/sign',
  REGISTRATION_CONFIRM: '/api/admin/v1/administrators/confirm',
  ADMINISTRATORS: '/api/admin/v1/administrators',
  ADMINISTRATOR: (id: number) => `/api/admin/v1/administrators/${id}`,
  ADMIN_RESET_PASSWORD: '/api/admin/v1/authorization/password/reset',
  ADMIN_CHANGE_PASSWORD: '/api/admin/v1/authorization/password/change',
  ADMIN_FORGOTTEN_PASSWORD: 'api/admin/v1/authorization/password/forgotten',
};

export const FORMS = {
  LOGIN_FORM: 'LOGIN_FORM',
  USER_FORM: 'USER_FORM',
  ADMINISTRATOR_FORM: 'ADMINISTRATOR_FORM',
  SET_NEW_PASSWORD_FORM: 'SET_NEW_PASSWORD_FORM',
  CHANGE_PASSWORD_FORM: 'CHANGE_PASSWORD_FORM',
  FORGOTTEN_PASSWORD_FORM: 'FORGOTTEN_PASSWORD_FORM',
  GROUP_FORM: 'GROUP_FORM',
  APPLICATION_FORM: 'APPLICATION_FORM',
  DEVICE_FORM: 'DEVICE_FORM',
  PASSWORD_CHANGE_FORM: 'PASSWORD_CHANGE_FORM',
};

export const LANGUAGE = {
  EN: 'en',
};

export const DeviceTabs = {
  Detail: 'device_detail',
  MessageHistory: 'device_message_history',
};

export const LANGUAGES = Object.values(LANGUAGE);

export const APPLICATION_TYPE = {
  APPLICATION: 'app',
  APPLICATION_UPDATER: 'au',
};

export const QRCODE_DIALOG = {
  QRCODE_SIZE: 280,
  WIDTH: 330,
};

export const APPLICATION_TYPES = Object.values(APPLICATION_TYPE);

export const APP_RELEASE_STATUS = {
  RELEASE: 'Release',
  DEVELOP: 'Develop',
};

export const APP_RELEASE_STATUSES = Object.values(APP_RELEASE_STATUS);
