import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { Select } from 'antd';
import { SelectFieldProps } from '../../types/atoms/props';

const SelectField = ({
  input,
  label,
  placeholder,
  showLabel,
  customClass,
  customLabelClass,
  customInputClass,
  meta: { touched, error },
  disabled,
  onKeyUp,
  required,
  onBlur,
  options,
  width,
  multiple,
  ...rest
}: SelectFieldProps) => {
  const checkValue = () => {
    if (!input.value && multiple) {
      return [] as string[];
    }
    return input.value;
  };

  return (
    <div className={cx('input-wrapper', { error: touched && error }, customClass)}>
      {showLabel && <label className={cx(customLabelClass, { required })}>{label}</label>}
      <div className={'input-field'}>
        <Select
          {...input}
          value={checkValue()}
          placeholder={placeholder}
          disabled={disabled}
          className={cx('input-field', customInputClass)}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          options={options}
          style={{ width }}
          mode={multiple ? 'multiple' : undefined}
          {...rest}
        />
      </div>
      <div className={'tooltip-error'}>{touched ? error : ''}</div>
    </div>
  );
};

SelectField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
  }).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showLabel: PropTypes.bool,
  customClass: PropTypes.string,
  customLabelClass: PropTypes.string,
  customInputClass: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
  width: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

SelectField.defaultProps = {
  label: '',
  placeholder: '',
  showLabel: true,
  customClass: null,
  customLabelClass: null,
  customInputClass: null,
  disabled: false,
  required: false,
  onKeyUp: null,
  onBlur: null,
  width: '100%',
  multiple: false,
  options: [],
};

export default SelectField;
