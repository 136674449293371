import React from 'react';
import { Progress } from 'antd';

interface ProgressBarProps {
  percent: number;
}

function ProgressBar(props: ProgressBarProps) {
  return <Progress percent={props.percent} />;
}

export default ProgressBar;
