import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import storageSession from 'redux-persist/es/storage/session';
import storageLocal from 'redux-persist/lib/storage';
import userReducers from './user/reducers';
import administratorsReducers from './administrators/reducers';
import devicesReducers from './devices/reducers';
import filtersReducer from './filters/reducers';
import groupsReducers from './groups/reducers';
import applicationsReduces from './applications/reducers';

export const REDUCER_KEYS = {
  FORM: 'form',
};

const rootReducer = combineReducers({
  form: persistReducer(
    {
      key: REDUCER_KEYS.FORM,
      storage: storageSession,
    },
    formReducer,
  ),
  user: persistReducer(
    {
      key: 'USER',
      storage: storageLocal,
    },
    userReducers,
  ),
  administrators: administratorsReducers,
  filters: filtersReducer,
  devices: devicesReducers,
  groups: groupsReducers,
  applications: applicationsReduces,
});

export type RootState = ReturnType<typeof formReducer>;
export default rootReducer;
