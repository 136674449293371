import React from 'react';
import PropTypes from 'prop-types';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { Button, Col, Divider, Modal, Row, Tooltip, Typography } from 'antd';
import { DeleteOutlined, LinkOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { get, map } from 'lodash';
import { FORMS } from '../../../utils/enums';
import { history } from '../../../utils/history';
import TextField from '../../../atoms/form/TextField';
import SelectField from '../../../atoms/form/SelectField';
import DetailHeader from '../../../components/DetailHeader';
import validateForm from './validateForm';
import { changeDeviceState, deleteDevice } from '../../../redux/devices/actions';
import DateField from '../../../atoms/form/DateField';
import moment from 'moment';
import { DeviceFormParams } from '../../../types/devices/page';
import { PatchDeviceModel } from '../../../types/devices/redux';
import { GroupModel } from '../../../types/groups/redux';
import { Link } from 'react-router-dom';
import SendCommandComponent from '../../../components/SendCommandComponent';

const { Title } = Typography;

const DeviceForm = (
  params: DeviceFormParams & InjectedFormProps<PatchDeviceModel, DeviceFormParams>,
) => {
  const { handleSubmit, invalid, pristine, isCreate } = params;
  const { t } = useTranslation(['components', 'pages', 'errors']);

  const dispatch = useDispatch();
  const fieldValues = useSelector((state) => getFormValues(FORMS.DEVICE_FORM)(state));
  const groups = useSelector((state) => get(state, 'groups.list.tableList'));

  const mapToSelectOptions = (groups: GroupModel[]) => {
    return map(groups, (item) => ({
      value: item.id,
      label: (
        <>
          <Link to={`/groups/${item.id}`}>
            <Tooltip title={t('pages:device.redirect_group')} placement={'left'}>
              <LinkOutlined />
            </Tooltip>
          </Link>
          <span style={{ marginRight: 10, marginLeft: 10 }}>{'|'}</span>
          {item.name}
        </>
      ),
    }));
  };

  const handleRemove = () => {
    Modal.confirm({
      title: t('pages:device.delete'),
      icon: <DeleteOutlined />,
      okText: t('components:delete'),
      cancelText: t('components:cancel'),
      okType: 'danger',
      onOk: () => {
        dispatch(
          deleteDevice(get(fieldValues, 'id'), () => {
            history.push('/devices');
          }),
        );
      },
    });
  };

  const handleChangeState = (command: string) => {
    Modal.confirm({
      title: t('pages:device.change_state_confirmation'),
      icon: <SaveOutlined />,
      okText: t('components:change_state'),
      cancelText: t('components:cancel'),
      onOk: () => {
        dispatch(changeDeviceState(get(fieldValues, 'id'), command));
      },
    });
  };

  const formatDatetimeRelative = (date: Date) => {
    if (date === null) {
      return '-';
    }
    return moment(date).fromNow();
  };

  const formatDateTime = (date: Date) => {
    if (date === null) {
      return '';
    }
    return moment(date).format('DD/MM/YYYY hh:mm:ss');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={18} className={'grid'}>
          <div className={'flex direction-col justify-start main-content'}>
            <DetailHeader title={t('pages:device.detail')} />
            <Field
              name={'chassisID'}
              component={TextField}
              label={t('pages:device.chassis_id')}
              disabled={true}
            />
            <Field
              name={'uuid'}
              component={TextField}
              label={t('pages:device.uuid')}
              disabled={true}
            />
            <Field
              name={'iccid'}
              component={TextField}
              label={t('pages:device.iccid')}
              disabled={true}
              placeholder={'-'}
            />
            <Field
              name={'groupID'}
              component={SelectField}
              options={mapToSelectOptions(groups)}
              label={t('pages:device.selected_group')}
              style={{ width: '100%', maxWidth: 400 }}
            />
            <Divider className={'divider'} />
            <Row>
              <Col xs={24} xl={6} className={'grid'}>
                <Field
                  name={'appVersionCode'}
                  component={TextField}
                  label={t('pages:device.app_version_code')}
                  disabled={true}
                />
                <Field
                  name={'appVersionName'}
                  component={TextField}
                  label={t('pages:device.app_version_name')}
                  disabled={true}
                />
              </Col>
              <Col xs={24} xl={6} className={'grid'}>
                <Field
                  name={'appUpdaterVersionCode'}
                  component={TextField}
                  label={t('pages:device.app_updater_version_code')}
                  disabled={true}
                />
                <Field
                  name={'appUpdaterVersionName'}
                  component={TextField}
                  label={t('pages:device.app_updater_version_name')}
                  disabled={true}
                />
              </Col>
              <Col xs={24} xl={6} className={'grid'}>
                <Field
                  name={'appLauncherVersionCode'}
                  component={TextField}
                  label={t('pages:device.app_launcher_version_code')}
                  disabled={true}
                />
                <Field
                  name={'appLauncherVersionName'}
                  component={TextField}
                  label={t('pages:device.app_launcher_version_name')}
                  disabled={true}
                />
              </Col>
              <Col xs={24} xl={6} className={'grid'}>
                <Field
                  name={'androidROMVersion'}
                  component={TextField}
                  label={t('pages:device.android_rom_version')}
                  disabled={true}
                />
                <Field
                  name={'androidROMType'}
                  component={TextField}
                  label={t('pages:device.android_rom_version_type')}
                  disabled={true}
                />
              </Col>
            </Row>
            <Divider orientation="left" className={'divider'}>
              <Title level={4}>{t('pages:device.battery')}</Title>
            </Divider>
            <Row gutter={10}>
              <Col xs={24} xl={2} className={'grid'}>
                <Field
                  name={'deviceBatteryStatus.mobileBatteryPercentage'}
                  component={TextField}
                  suffix="%"
                  label={t('pages:device.mobile')}
                  disabled={true}
                />
              </Col>
              <Col xs={24} xl={2} className={'grid'}>
                <Field
                  name={'deviceBatteryStatus.motherboardBatteryPercentage'}
                  component={TextField}
                  suffix="%"
                  label={t('pages:device.motherboard')}
                  disabled={true}
                />
              </Col>
              <Col xs={24} xl={5} className={'grid disabled-input-darker'}>
                <Field
                  name={'deviceBatteryStatus.updatedAt'}
                  component={DateField}
                  label={t('pages:device.last_updated_at')}
                  showTime={true}
                  disabled={true}
                />
              </Col>
            </Row>
            <Divider className={'divider'} />
            <Field
              name={'glucometerID'}
              component={TextField}
              label={t('pages:device.glucometer_id')}
              disabled={true}
            />
            <Field
              name={'thermometerID'}
              component={TextField}
              label={t('pages:device.thermometer_id')}
              disabled={true}
            />
            <Field
              name={'bloodPressureMonitorID'}
              component={TextField}
              label={t('pages:device.blood_pressure_monitor_id')}
              disabled={true}
            />
            <Field
              name={'hardwareID'}
              component={TextField}
              label={t('pages:device.hardware_id')}
              disabled={true}
            />
            <Field
              name={'mobileIMEI'}
              component={TextField}
              label={t('pages:device.imei')}
              disabled={true}
            />
            <Field
              name={'poID'}
              component={TextField}
              label={t('pages:device.po_id')}
              disabled={true}
            />
            <Field
              name={'updaterNodeToken'}
              component={TextField}
              label={t('pages:device.au_pushy_id')}
              disabled={true}
            />
            <Field
              name={'applicationNodeToken'}
              component={TextField}
              label={t('pages:device.app_pushy_id')}
              disabled={true}
            />
          </div>
        </Col>
        <Col span={6} className={'grid'}>
          <div className={'flex direction-col justify-start sidebar-content'}>
            <div className={'flex direction-col justify-center'}>
              <Field
                name={'state'}
                component={TextField}
                label={t('pages:device.state')}
                disabled={true}
              />

              <Tooltip
                title={formatDateTime(get(fieldValues, 'lastSeen'))}
                placement={'bottomLeft'}
                popupVisible={true}
              >
                <div>
                  <Field
                    name={'lastSeen'}
                    component={TextField}
                    format={formatDatetimeRelative}
                    label={t('pages:device.lastSeen')}
                    disabled={true}
                  />
                </div>
              </Tooltip>
              <SendCommandComponent onHandleCommand={handleChangeState} />
              <Divider />
              <Button
                icon={<SaveOutlined />}
                onClick={handleSubmit}
                disabled={pristine || invalid}
                type={'primary'}
                style={{ margin: '20px' }}
              >
                {t('components:save')}
              </Button>
              {!isCreate && (
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemove()}
                  style={{ margin: '20px' }}
                  danger={true}
                >
                  {t('components:delete')}
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </form>
  );
};

DeviceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isCreate: PropTypes.bool,
};

export default reduxForm<PatchDeviceModel, DeviceFormParams>({
  form: FORMS.DEVICE_FORM,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  touchOnChange: true,
  validate: validateForm,
})(DeviceForm);
