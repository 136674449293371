import i18next from 'i18next';
import { FormErrors } from 'redux-form';
import { ApplicationModel } from '../../../types/applications/redux';

export default (values: ApplicationModel) => {
  const errors: FormErrors<ApplicationModel> = {};
  if (!values.name) {
    errors.name = i18next.t('errors:this_field_is_required');
  }

  if (!values.code) {
    errors.code = i18next.t('errors:this_field_is_required');
  }

  if (!values.fileUrl) {
    errors.fileUrl = i18next.t('errors:this_field_is_required');
  }

  return errors;
};
