import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { FORMS } from '../../../utils/enums';
import validateLoginForm from './validateLoginForm';
import TextField from '../../../atoms/form/TextField';
import { LoginFormProps } from '../../../types/authorization/loginPages';

const LoginForm = ({ handleSubmit, invalid, pristine }: LoginFormProps) => {
  const { t } = useTranslation(['pages', 'components', 'errors']);

  const user = useSelector((state) => get(state, 'user'));

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name={'email'}
        label={t('pages:login.email')}
        type={'text'}
        placeholder={t('pages:login.email')}
        component={TextField}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      />
      <Field
        name={'password'}
        label={t('pages:login.password')}
        type={'password'}
        placeholder={t('pages:login.password')}
        component={TextField}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      />
      <Form.Item>
        <Button
          type={'primary'}
          htmlType={'submit'}
          loading={get(user, 'isLoading')}
          disabled={invalid || pristine}
        >
          {t('components:sign_in')}
        </Button>
      </Form.Item>
    </form>
  );
};

LoginForm.propTypes = {
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.LOGIN_FORM,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  touchOnChange: true,
  validate: validateLoginForm,
})(LoginForm);
