import {
  LOAD_DEVICE,
  LOAD_DEVICES,
  UPDATE_DEVICE,
  LOAD_GROUP_DEVICES,
  LOAD_MESSAGE,
  LOAD_DEVICE_REGISTRATION_TOKEN,
  DeviceAction,
  DeviceState,
} from '../../types/devices/redux';

export const initState: DeviceState = {
  list: {
    tableList: [],
    context: {
      page: 0,
      count: 0,
      totalCount: 0,
    },
    isLastPage: false,
    isLoading: false,
    isFailure: false,
  },
  assignedList: {
    tableList: [],
    context: {
      page: 0,
      count: 0,
      totalCount: 0,
    },
    isLastPage: false,
    isLoading: false,
    isFailure: false,
  },
  detail: {
    isLoading: false,
    isFailure: false,
  },
};

export default (state = initState, action: DeviceAction) => {
  switch (action.type) {
    case LOAD_DEVICES.START:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLastPage: false,
          isLoading: true,
          isFailure: false,
        },
      };
    case LOAD_DEVICES.DONE:
      return {
        ...initState,
        ...state,
        list: {
          ...action.payload,
          isLoading: false,
          isFailure: false,
        },
      };
    case LOAD_DEVICES.FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          isLastPage: false,
          isLoading: false,
          isFailure: true,
        },
      };
    case LOAD_GROUP_DEVICES.START:
      return {
        ...initState,
        ...state,
        assignedList: {
          ...state.list,
          isLastPage: false,
          isLoading: true,
          isFailure: false,
        },
      };
    case LOAD_GROUP_DEVICES.DONE:
      return {
        ...initState,
        ...state,
        assignedList: {
          ...action.payload,
          isLoading: false,
          isFailure: false,
        },
      };
    case LOAD_GROUP_DEVICES.FAILED:
      return {
        ...state,
        assignedList: {
          ...state.list,
          isLastPage: false,
          isLoading: false,
          isFailure: true,
        },
      };
    case LOAD_DEVICE.START:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case LOAD_DEVICE.DONE:
      return {
        ...initState,
        ...state,
        detail: {
          data: action.payload,
          isLoading: false,
          isFailure: false,
        },
      };
    case LOAD_DEVICE.FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    case UPDATE_DEVICE.START:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case UPDATE_DEVICE.DONE:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: false,
        },
      };
    case UPDATE_DEVICE.FAILED:
      return {
        ...initState,
        ...state,
        detail: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    case LOAD_MESSAGE.START:
      return {
        ...initState,
        ...state,
        list: {
          ...state.list,
          isLastPage: false,
          isLoading: true,
          isFailure: false,
        },
      };
    case LOAD_MESSAGE.DONE:
      return {
        ...initState,
        ...state,
        list: {
          ...action.payload,
          isLoading: false,
          isFailure: false,
        },
      };
    case LOAD_MESSAGE.FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          isLastPage: false,
          isLoading: false,
          isFailure: true,
        },
      };
    case LOAD_DEVICE_REGISTRATION_TOKEN.START:
      return {
        ...initState,
        ...state,
        token: {
          ...state.detail,
          isLoading: true,
          isFailure: false,
        },
      };
    case LOAD_DEVICE_REGISTRATION_TOKEN.DONE:
      return {
        ...initState,
        ...state,
        token: {
          data: action.payload,
          isLoading: false,
          isFailure: false,
        },
      };
    case LOAD_DEVICE_REGISTRATION_TOKEN.FAILED:
      return {
        ...state,
        token: {
          ...state.detail,
          isLoading: false,
          isFailure: true,
        },
      };
    default:
      return state;
  }
};
