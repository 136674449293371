import React, { useState } from 'react';
import { Layout, BackTop } from 'antd';
import PropTypes from 'prop-types';
import SideMenu from '../components/SideMenu';
import HeaderContent from '../components/HeaderContent';
import { AdminLayoutProps } from '../types/other';

const { Header, Content, Sider } = Layout;

function AdminLayout({ routeKey, children, computedMatch }: AdminLayoutProps) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible={true} collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
        <div className={'app-logo'} />
        <SideMenu routeKey={routeKey} />
      </Sider>
      <Layout>
        <Header className={'site-layout-background'} style={{ padding: 0 }}>
          <HeaderContent routeKey={routeKey} computedMatch={computedMatch} />
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <div className={'content-box'}>{children}</div>
          <BackTop />
        </Content>
      </Layout>
    </Layout>
  );
}

AdminLayout.propTypes = {
  routeKey: PropTypes.string,
  children: PropTypes.any,
  computedMatch: PropTypes.shape({}),
};

AdminLayout.defaultProps = {
  routeKey: '',
  computedMatch: null,
};

export default AdminLayout;
