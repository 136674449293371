import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Table, Spin, Empty, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeviceModel } from '../types/devices/redux';
import { ColumnsType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { ENDPOINTS } from '../utils/enums';
import { GetGroupListData } from '../types/groups/redux';
import { getReq } from '../utils/request';
import { GroupDevicesModel } from '../types/groups/page';

interface DataType {
  key: React.Key;
  chassisID: string;
  mobileIMEI: string;
  appVersionCode: number;
}

interface DeviceOnGroupsComponentProps {
  onMove?: (groupDevices: GroupDevicesModel) => void;
  devicesDataSource?: DeviceModel[];
  groupId: string;
}

interface GroupOption {
  value: number;
  key: string;
  label: string;
}

const DeviceOnGroupsComponent = ({
  onMove,
  devicesDataSource,
  groupId,
}: DeviceOnGroupsComponentProps) => {
  const { t } = useTranslation(['pages', 'paths', 'components']);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DataType[]>([]);
  const [groups, setGroups] = useState<GroupOption[]>([]);
  const [group, setGroup] = useState<GroupOption>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [lastSelectedKey, setLastSelectedKey] = useState<React.Key | null>(null);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const { data } = await getReq<GetGroupListData>(ENDPOINTS.GROUPS);
        const groups = data.groups
          .filter((x) => x.id !== parseInt(groupId))
          .map((x) => ({
            key: x.id.toString(),
            label: x.name,
            value: x.id,
          }));
        setGroups(groups);
      } catch (error) {
        console.error('Failed to fetch groups:', error);
      }
    };

    fetchGroups();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (devicesDataSource && devicesDataSource.length > 0) {
          const mappedData = devicesDataSource.map((value) => ({
            key: value.id,
            chassisID: value.chassisID,
            mobileIMEI: value.mobileIMEI,
            appVersionCode: value.appVersionCode,
          }));

          setData(mappedData);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error('Failed to map data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [devicesDataSource]);

  const columns: ColumnsType<DataType> = [
    {
      title: t('pages:device.chassis_id'),
      dataIndex: 'chassisID',
      defaultSortOrder: 'ascend',
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search name`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value: string | number | boolean, record: DataType) => {
        if (typeof value === 'string') {
          return record.chassisID.toLowerCase().includes((value as string).toLowerCase());
        }
        return false;
      },
      sorter: (a, b) => a.chassisID.localeCompare(b.chassisID),
    },
    {
      title: t('pages:device.mobile_imei'),
      dataIndex: 'mobileIMEI',
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search name`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value: string | number | boolean, record: DataType) => {
        if (typeof value === 'string') {
          return record.mobileIMEI.toLowerCase().includes((value as string).toLowerCase());
        }
        return false;
      },
      sorter: (a, b) => a.mobileIMEI.localeCompare(b.mobileIMEI),
    },
    {
      title: t('pages:device.current_app_version'),
      dataIndex: 'appVersionCode',
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search name`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value: string | number | boolean, record: DataType) => {
        if (typeof value === 'string') {
          return record.appVersionCode
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase());
        }
        return false;
      },
      sorter: (a, b) => a.appVersionCode - b.appVersionCode,
    },
  ];

  const handleSelectionChange = (value: GroupOption) => {
    setGroup(value);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleRowClick = (record: DataType, event: React.MouseEvent) => {
    let newSelectedRowKeys = [...selectedRowKeys];
    const index = newSelectedRowKeys.indexOf(record.key);

    if (event.shiftKey && lastSelectedKey) {
      const currentIndex = data.findIndex((item) => item.key === record.key);
      const lastIndex = data.findIndex((item) => item.key === lastSelectedKey);

      const range = [Math.min(currentIndex, lastIndex), Math.max(currentIndex, lastIndex)];
      const keysInRange = data.slice(range[0], range[1] + 1).map((item) => item.key);

      if (index >= 0) {
        newSelectedRowKeys = newSelectedRowKeys.filter((key) => !keysInRange.includes(key));
      } else {
        keysInRange.forEach((key) => {
          if (!newSelectedRowKeys.includes(key)) {
            newSelectedRowKeys.push(key);
          }
        });
      }
    } else {
      if (index >= 0) {
        newSelectedRowKeys.splice(index, 1);
      } else {
        newSelectedRowKeys.push(record.key);
      }
    }

    setSelectedRowKeys(newSelectedRowKeys);
    setLastSelectedKey(record.key);
  };

  const onMoveHandle = () => {
    const selectedRows: DataType[] = selectedRowKeys
      .map((key) => data.find((row) => row.key === key))
      .filter(Boolean) as DataType[];
    const deviceIds = selectedRows.map((x) => x.key as number);
    if (group) {
      const groupDevices: GroupDevicesModel = {
        groupId: group.value,
        deviceIds: deviceIds,
      };
      if (onMove) {
        onMove(groupDevices);
      }
    }
  };

  return (
    <>
      <div className={'flex direction-row justify-start'}>
        <div className={'flex direction-row justify-center'}>
          <Row style={{ width: '100%' }}>
            <Col className={'grid'} style={{ width: '60%' }}>
              {loading ? (
                <Spin size="large" />
              ) : (
                <Table
                  dataSource={data}
                  showSorterTooltip={false}
                  columns={columns}
                  pagination={false}
                  rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys,
                    onChange: onSelectChange,
                  }}
                  scroll={{ y: 420 }}
                  style={{ height: '500px', border: '1px solid #ccc' }}
                  locale={{
                    emptyText: <Empty description={t('components:empty_list')} />,
                  }}
                  size={'small'}
                  onRow={(record) => ({
                    onClick: (event) => handleRowClick(record, event),
                  })}
                  summary={() => (
                    <Table.Summary fixed="bottom">
                      <Table.Summary.Row
                        style={{
                          backgroundColor: '#f0f0f0',
                          color: '#000',
                        }}
                      >
                        <Table.Summary.Cell index={0} colSpan={4} align="right">
                          <div style={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
                            <span style={{ paddingRight: '0.5rem', fontWeight: 'bold' }}>
                              Count:
                            </span>
                            <span style={{ paddingRight: '1rem', fontWeight: 'bold' }}>
                              {data.length}
                            </span>
                          </div>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                />
              )}
            </Col>
            <Col
              className={'grid'}
              style={{
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'top',
                alignItems: 'center',
              }}
            >
              <Row style={{ width: '100%', marginBottom: '16px' }}>
                <Select
                  labelInValue
                  showSearch
                  style={{ width: '100%', paddingLeft: '16px' }}
                  onChange={handleSelectionChange}
                  placeholder={t('components:select_group')}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={groups}
                />
              </Row>
              <Row>
                <Button onClick={onMoveHandle}>{t('components:move')}</Button>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default DeviceOnGroupsComponent;
